import React, { useLayoutEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import SideBarCustom from '../sidebar/SideBarCustom';
import Header from './Header'
export function Layout() {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
    return (
        <div className="flex">
            <aside className="w-fit">
                <div className='h-screen sticky overflow-y-scroll top-0 z-[99999] scrollbar-hide'>
                    <SideBarCustom />
                </div>
            </aside>

            <main className='w-full mx-6 xs:mx-2 mt-6'>
                <Header />
                <div className='w-full mt-6 xs:mt-14 mb-20'>
                    <Outlet />
                </div>
            </main>
        </div>
    )
}

export default Layout