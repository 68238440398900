import React from 'react'

export function Card({ data }) {
    return (
        <div className='flex justify-between p-5 bg-white w-[260px] h-32 rounded-[5px]'>
            <div className='flex flex-col justify-between'>
                <div>
                    <h4 className='text-base font-semibold'>{data.title}</h4>
                    <p className='text-sm font-normal text-[#00000099]'>{data.subtitle}</p>
                </div>
                <h5 className='text-sm font-normal text-[#00000099]'>{data.text}</h5>
            </div>
            <div className='w-[54px] h-[54px] rounded-[5px] bg-[#E18A07]'></div>


        </div>
    )
}

export default Card