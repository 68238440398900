import React from 'react'
import { TransactionsTable } from '../components/transactions'

export function Transactions() {
    return (
        <div>
            <h1 className='text-2xl xs:text-xl font-bold'>Transactions</h1>
            <h3 className='text-xl xs:text-lg font-medium text-[#00000099]'>Your Transactions History</h3>
            <div className='mt-9'>
                <TransactionsTable />
            </div>


        </div>
    )
}

export default Transactions