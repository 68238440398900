import React from 'react'
import Form from '../components/settings/Form'

export function Settings() {
    return (
        <div>
            <h1 className='text-2xl xs:text-xl font-bold'>Settings</h1>
            <div className='mt-9'>
                <Form />
            </div>


        </div>
    )
}

export default Settings