import React from 'react'
import Item from '../components/usageGuide/Item'
export function UsageGuide() {
    return (
        <div className='w-7/12 xs:w-full ml-3 xs:ml-0'>
            <h1 className='text-[43px] xs:text-2xl font-bold'>How to master easily Reddit</h1>
            <h3 className='mt-2 text-[22px] xs:text-lg text-[#00000099] font-medium'>With our simple steps guide for use our website</h3>
            <h2 className='mt-[60px] xs:mt-10 text-2xl xs:text-lg font-bold'>Fill balance to obtain credits and use <br />the services with <span className='text-[#E18A07]'> 3 simple steps</span></h2>
            <ul className='mt-7 flex flex-col gap-y-4'>
                <Item step="STEP 1 - " text="Register an account at " link="https://panel.upvote.store" />
                <Item step="STEP 2 - " text="Recharge the balance at " link="https://panel.upvote.store/deposit" />
                <Item step="STEP 3 - " text="Then you can use the balance to place an order." link="" />
            </ul>
            <h2 className='mt-[80px] xs:mt-14 text-2xl xs:text-lg font-bold'>Make your order</h2>
            <ul className='mt-7 flex flex-col gap-y-4'>
                <Item step="STEP 1 - " text="Go to section " link="https://panel.upvote.shop/new-order" />
                <Item step="STEP 2 - " text="Select the type of service and the custom speed you want" link="" />
                <Item step="STEP 3 - " text="Put all the links from the posts, comments or subreddits one below the other" link="" />
                <Item step="STEP 4 - " text="Select the number of votes or subscribers you want" link="" />
                <Item step="STEP 5 - " text="Select when you want the service delivery to begin" link="" />
                <Item step="STEP 6 - " text='Press the "Place Order" button' link="" />
            </ul>
            <h2 className='mt-[60px] xs:mt-10 text-2xl xs:text-lg font-bold'>Before you submit your link</h2>
            <ul className='mt-7 flex flex-col gap-y-4'>
                <Item step="" text='Make sure that your links are live.' link="" />
                <Item step="" text='Make sure that your contents meet the rules of the targeted subreddits' link="" />
            </ul>
            <h2 className='mt-[80px] xs:mt-14 text-2xl xs:text-lg font-bold'>Tips so that your account does not fall into bann</h2>
            <ul className='mt-7 flex flex-col gap-y-4'>
                <Item step="" text='During the first hour of the post never give it more than 50 upvotes' link="" />
                <Item step="" text='If you are going to give a large number of upvotes to your post or comment (+150), it is best that you schedule the delivery of upvotes to take place during a couple of hours, this way they will not detect what you are doing' link="" />
                <Item step="" text="Don't always take your post to the top 1 of the same subreddit literally
                    every day." link="" />
            </ul>
        </div>
    )
}

export default UsageGuide