import React from 'react'
// import PaymentModal from './PaymentModal'
import { openModal } from '../modals/control'

export function PlanCard({ icon, title, features }) {
    return (
        <div>

            <div className={`flex flex-col justify-between items-center w-[231px] h-[320px] bg-white border py-3 rounded-[15px] border-solid border-[rgba(0,0,0,0.20)]`}>
                <h1 className='text-[30px] font-black text-center'>{title}</h1>
                <div>

                    <div className='mt-2 w-[124px]  flex justify-center '>
                        <img src={icon} alt="" />
                    </div>
                    <div className='mt-3 flex flex-col gap-y-[10px] text-center text-base  font-bold'>
                        {
                            features.map((item, index) => <h3 key={index}>{item}</h3>)
                        }
                    </div>
                </div>
            </div>
            {
                <div className='w-full flex justify-center'>

                    <div className='btn flex justify-center items-center bg-white mt-6 w-[200px] h-10 border border-solid rounded-[8px] border-[rgba(0,0,0,0.20)] cursor-pointer hover:bg-[#E18A07] hover:border-none hover:text-white'
                        onClick={() => {
                            openModal("payments_modal")
                        }}
                    >
                        <p className='text-sm font-bold'>Buy Now</p>
                    </div>
                </div>
                // :
                // <div className='btn flex justify-center items-center bg-transparent mt-6 w-[231px] h-10 border rounded-[20px] border-solid border-[#242424] cursor-pointer'
                //     onClick={() => openModal("payments_modal")}
                // >
                //     <p className='text-sm font-bold'>Buy Now</p>
                // </div>

            }
            {/* <PaymentModal active={active} /> */}

        </div>
    )
}

export default PlanCard