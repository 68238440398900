import React from 'react'
import { Card, Banner, LatestOrders, FilterBtn } from '../components/dashboard'

export function Dashboard() {
    return (
        <div className='' >
            <Banner />
            {/* cards */}
            <div className="mt-5">
                <h4 className="text-2xl xs:text-xl font-bold">Dashboard</h4>
                <div className='flex xs:flex-col xs:gap-y-4 gap-x-8 mt-4'>
                    <Card data={{ title: "0.00 Credits", subtitle: "~ 0 upvotes", text: "Available Balance" }} />
                    <Card data={{ title: "0.00 $", subtitle: "Total Deposited", text: "" }} />
                    <Card data={{ title: "0.00 Credits", subtitle: "Credits Used in 24h", text: "" }} />
                </div>
            </div>
            {/* Latest Orders */}
            <div className="mt-12">
                <div className='flex xs:flex-col xs:gap-y-4 justify-between'>
                    <h4 className="text-2xl xs:text-xl font-bold">Latest Orders Historical</h4>
                    <div className='flex justify-start xs:pr-2'>
                        <FilterBtn />
                    </div>
                </div>
                <LatestOrders />
            </div>
        </div >
    )
}

export default Dashboard