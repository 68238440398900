import React from 'react'
import close from '../../assets/images/icons/close.svg'
import hello from '../../assets/images/icons/hello.svg'
import { closeModal, openModal } from '../modals/control'
import { authStore } from '../../contect/auth'
export function LoginModal() {
    const { login } = authStore()
    return (
        <div>
            <div className=' w-32 h-10 flex justify-center items-center px-10 py-2.5 rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424]  cursor-pointer'
                onClick={() => openModal("login_modal")}
            >
                <p className=' text-sm font-bold '>
                    Log in
                </p>
            </div>
            <dialog id="login_modal" className="modal glass1">
                <div className=' h-fit w-fit relative'>
                    <div className="modal-box w-[511px] max-w-[511px] p-6 rounded-[5px] relative xs:h-fit1 xs:max-h-fit1"
                        style={{ maxWidth: window.innerWidth > 660 ? "511px" : window.innerWidth - 50 }}
                    >
                        <div className=' flex flex-row-reverse absolute top-5 right-5'>
                            <button className='focus-visible:outline-none'
                                onClick={() => closeModal("login_modal")}
                            ><img src={close} alt="" /></button>
                        </div>
                        <h1 className='text-[35px] xs:text-2xl font-bold text-center'>Welcome back</h1>
                        <h1 className='text-2xl xs:text-lg font-normal text-[#00000099] text-center'>Please fill the following details to login.</h1>

                        <div className='mt-10 xs:mt-6'>
                            <label className="label">
                                <span className="text-xl xs:text-base font-medium text-[#00000099]">Email address</span>
                            </label>
                            <input type="email" placeholder="Email address" className="input input-bordered w-full h-[52px] bg-transparent" />
                        </div>
                        <div className='mt-6 xs:mt-3'>
                            <label className="label">
                                <span className="text-xl xs:text-base font-medium text-[#00000099]">Password</span>
                            </label>
                            <input type="password" placeholder="Password" className="input input-bordered w-full h-[52px] bg-transparent" />
                        </div>
                        <p className='mt-2 text-[#00000099] text-base font-normal'>Forgot Password?</p>
                        <div className="flex justify-center items-center w-full">
                            <div className='mt-8 xs:mt-6 w-[415px] xs:w-full h-12 flex justify-center items-center rounded-[20px] bg-[#F90] cursor-pointer'
                                onClick={() => {
                                    login()
                                    closeModal("login_modal")
                                }}
                            >
                                <p className='text-white text-sm font-bold '>
                                    Login
                                </p>
                            </div>
                        </div>
                        <p className='mt-6 xs:mt-4 text-[#6A6C71] text-base font-normal text-center'>Don’t have an account? <span className='text-[#FF9900] underline cursor-pointer'
                            onClick={() => openModal("register_modal")}
                        >Create account</span></p>


                    </div>
                    <div className='absolute bottom-[-50px] right-[-155px] xs:right-[-180px] xs:bottom-[-20px] z-[999999]'>
                        <img src={hello} alt="" className='xs:w-2/6 xs:hidden' />
                    </div>
                </div>

            </dialog>
        </div>
    )
}

export default LoginModal