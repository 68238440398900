import React from 'react'
import award_1 from '../assets/images/icons/award-1.svg'
import award_2 from '../assets/images/icons/award-2.svg'
import award_3 from '../assets/images/icons/award-3.svg'
import award_4 from '../assets/images/icons/award-4.svg'
import award_5 from '../assets/images/icons/award-5.png'
import award_6 from '../assets/images/icons/award-6.png'
import DiscountRow from '../components/customerRange/DiscountRow'
import downDouble from '../assets/images/icons/Capa44.svg'
import star from '../assets/images/icons/star44.svg'

export function CustomerRange() {
    return (
        <div className='mr-20 xs:mr-0'>
            <div className="divider divider-start after:bg-black"><h1 className='text-2xl xs:text-xl font-bold'>Your Customer Range</h1></div>
            <div className='flex xs:gap-x-6 w-full mt-16'>
                <div className='w-2/6 ml-6 xs:ml-0'>
                    <h2 className='pl-10 xs:pl-0 xs:text-center text-2xl xs:text-xl font-bold '>Range</h2>
                    <div className='w-[158px] xs:w-[120px] h-[150px] xs:h-[120px] mt-6 flex justify-center items-center rounded-[9px] bg-[#FF4607]'>
                        <img src={award_1} alt="" className='xs:w-3/6' />
                    </div>
                </div>
                <div className='w-3/6 xs:w-4/6 '>
                    <h2 className='ml-24 xs:ml-0 xs:text-center text-2xl xs:text-xl font-bold '>Benefits you get:</h2>
                    <ul className='mt-10 xs:ml-2 flex flex-col gap-y-5 text-[#000000CC] text-xl xs:text-base font-extrabold'>
                        <li className='flex items-center gap-x-5'><img src={downDouble} alt="" />
                            12% Discount for life on all your purchases</li>
                        <li className='flex items-center gap-x-5'><img src={star} alt="" />
                            10000 Free credits</li>
                    </ul>
                </div>

            </div>
            <div className="mt-[86px] xs:mt-16 mb-[70px] xs:mb-8 divider divider-start after:bg-black"><h1 className='text-2xl xs:text-xl font-bold'>Board</h1></div>
            <DiscountRow award={award_2} content={{ price: "10000$", desc: "20% Discount for life on all your \n purchases", value: "10000 Free Credits" }} />
            <div className="divider mb-0"></div>
            <DiscountRow award={award_3} content={{ price: "5000$", desc: "10% Discount for life on all your \n purchases", value: "10000 Free Credits" }} />
            <div className="divider mb-0"></div>
            <DiscountRow award={award_4} content={{ price: "2000$", desc: "7% Discount for life on all your \n purchases", value: "2000 Free Credits" }} />
            <div className="divider mb-0"></div>
            <DiscountRow award={award_5} content={{ price: "500$", desc: "5% Discount for life on all your \n purchases", value: "500 Free Credits" }} />
            <div className="divider mb-0"></div>
            <DiscountRow award={award_6} content={{ price: "100$", desc: "Custom Orders", value: "150 Free Credits" }} />
            <div className="divider mb-0"></div>

        </div>
    )
}

export default CustomerRange