import React from 'react'

export function Form() {
    return (
        <div className='card bg-base-100 w-[900px] xs:w-full px-6 pt-5 pb-10 rounded-[5px]' >
            <div className="form-control ">
                <div className='flex'>
                    <div className='w-1/2 xs:w-full'>
                        <label className="label">
                            <span className="text-xl xs:text-base font-medium text-[#00000099]">Email Address- Resend Verification Email</span>
                        </label>
                        <input type="email" placeholder="Email address" className="input input-bordered w-full h-[52px]" />
                    </div>
                    <span className="text-lg xs:text-sm ml-3 flex items-end text-[#00000099] font-normal">(Unconfirmed)</span>
                </div>

                <div className='w-1/2 xs:w-full mt-8'>
                    <label className="label">
                        <span className="text-xl xs:text-base font-medium text-[#00000099]">Old Password</span>
                    </label>
                    <input type="password" placeholder="Password" className="input input-bordered w-full h-[52px]" />
                </div>
                <div className='w-1/2 xs:w-full mt-8'>
                    <label className="label">
                        <span className="text-xl xs:text-base font-medium text-[#00000099]">New Password</span>
                    </label>
                    <input type="password" placeholder="Password" className="input input-bordered w-full h-[52px]" />
                </div>
                <div className='w-1/2 xs:w-full mt-8'>
                    <label className="label">
                        <span className="text-xl xs:text-base font-medium text-[#00000099]">Confirm Password</span>
                    </label>
                    <input type="password" placeholder="Password" className="input input-bordered w-full h-[52px]" />
                </div>

            </div>
            <div className='mt-8 xs:flex xs:justify-center'>
                <div className='mt-4 w-[260px] h-[44px] flex justify-center items-center px-10 py-2.5 rounded-[20px] bg-[#242424] cursor-pointer'>
                    <p className='text-white text-base font-bold '>
                        Save Changes
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Form