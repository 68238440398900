import React from 'react'
import close from '../../assets/images/icons/close.svg'
import bill from '../../assets/images/icons/bill.svg'
import { closeModal, openModal } from '../modals/control'
export function PayModal({ plan = { price: "$10" }, payType = "Pay with card" }) {
    const modalKey = "pay_modal" + plan.price

    return (
        <div>
            <div className='flex justify-center items-center bg-transparent w-[108px] h-10 border rounded-[20px] border-solid border-[#242424] cursor-pointer hover:bg-[#E18A07] hover:border-none hover:text-white'
                onClick={() => {
                    openModal(modalKey)
                }}
            >
                <p className='text-sm font-bold'>Buy Now</p>
            </div>

            <dialog id={modalKey} className="modal glass1">
                <div className="modal-box w-[601px] max-w-[601px] py-[35px] px-[30px] xs:px-5 rounded-[5px] relative"
                    style={{ maxWidth: window.innerWidth > 650 ? "601px" : window.innerWidth - 50 }}
                >
                    <div className=' flex flex-row-reverse absolute top-5 right-5'>
                        <button className='focus-visible:outline-none'
                            onClick={() => closeModal(modalKey)}
                        ><img src={close} alt="" /></button>
                    </div>
                    <div className='text-center text-[35px] xs:text-2xl font-bold'>
                        <h1 className=''>You have selected the <br />{plan.price} Package</h1>
                    </div>
                    <div className='divider my-2'></div>
                    <div className='text-[#00000099] text-xl xs:text-base font-medium'>
                        <div className='flex justify-between mx-20 xs:mx-0'>
                            <p>Package</p>
                            <p>{plan.price}</p>
                        </div>
                        <div className='divider my-2'></div>
                        <div className='flex justify-between mx-20 xs:mx-0'>
                            <p>Total Credits You Get</p>
                            <p>100</p>
                        </div>
                        <div className='divider my-2'></div>
                        <div className='flex justify-between mx-20 xs:mx-0'>
                            <p>Total Upvotes You Get</p>
                            <p>1000</p>
                        </div>
                        <div className='divider my-2'></div>
                    </div>
                    <div className='my-6 flex xs:flex-col xs:gap-y-3 xs:justify-center xs:items-center xs:w-full text-[#00000099] text-left text-base font-medium'>
                        <ul className='w-1/2 xs:w-full flex flex-col gap-y-3'>
                            <li>✔️ 1 Credit = 1 post Upvotes</li>
                            <li>✔️ 1.25 Credit = 1 post Downvote</li>
                            <li>✔️ 1 Credit = 1 Comment Upvote</li>
                            <li>✔️ 1 Credit = 1 Comment Downvote</li>
                            <li>✔️ 1 Credit = 1 Subscriber</li>

                        </ul>
                        <ul className='w-1/2 xs:w-full flex flex-col gap-y-3'>
                            <li>✔️ 1 Credit = 1 post Upvotes</li>
                            <li>✔️ Taking advantage of limited offer</li>
                            <li>✔️ Using the one time offer</li>
                            <li>✔️ Instant Upvote Delivery</li>
                            <li>✔️ Adjustable Vote Speed</li>
                        </ul>
                    </div>
                    <div className='divider my-2'></div>
                    <div className='mt-6 xs:mt-4 flex justify-center'>
                        <div className='flex xs:flex-col xs:gap-y-3 gap-x-4 xs:w-full'>
                            <label className="label">
                                <span className="text-xl xs:text-base font-medium text-[#00000099]">Enter Code</span>
                            </label>
                            <div className='flex justify-center gap-x-4'>
                                <input type="text" placeholder="Enter Coupon Code" className="input input-bordered w-[226px] xs:w-full h-[52px] bg-transparent" />

                                <div className=' w-[120px] h-12 flex justify-center items-center rounded-[20px] bg-black cursor-pointer'
                                >
                                    <p className='text-white text-sm font-bold '>
                                        Apply
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mt-10 flex justify-center items-center w-full">
                        <div className=' w-[415px] h-12 flex justify-center items-center rounded-[20px] bg-[#FF9900] cursor-pointer'
                        >
                            <p className='text-white text-sm font-bold '>
                                {payType}
                            </p>
                        </div>

                    </div>

                    <div className='mt-5 flex justify-center items-center gap-x-2'>
                        <img src={bill} alt="" />
                        <p className='text-base xs:text-sm font-medium text-center '>You will be moved to our another page to complete the pay</p>
                    </div>

                </div>
            </dialog>
        </div>
    )
}

export default PayModal