import React from 'react'
import icon from '../../assets/images/icons/flame.svg'
export function Item({ step, text, link = "" }) {
    return (
        <li className='flex'>
            <img src={icon} alt="" className='mr-4 xs:mr-2 w-[10px] h-[18px] mt-1' />
            <h3 className='text-lg xs:text-base font-semibold'>{step} <span className='text-[#00000099] font-medium'>{text}</span> {link && <a href={link} className='text-[#E18A07] font-medium'>{link}</a>}</h3>

        </li>
    )
}

export default Item