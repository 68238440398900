import React from 'react'

export function Reviews({ img, name, date, content, rate }) {
    const ratingKey = Math.random() + name + date
    return (
        <div className='flex gap-x-4 w-5/6 xs:w-full'>
            <div className='w-3/12 flex flex-col justify-start items-center text-center '>
                <img src={img} alt={name} className='rounded-full xs:w-12 xs:h-12' />
                <p className='mt-3 text-[22px] xs:text-base font-medium'>{name}</p>
                <p className='text-sm text-[#00000066] font-medium'>{date}</p>
            </div>
            <div className='w-9/12'>
                <p className='text-xl xs:text-base font-normal'>{content}</p>
                {/* <div className="rating">
                    <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                    <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" checked />
                    <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                    <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                    <input type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                </div> */}
                <div className="flex items-center rating mt-[14px]">
                    {
                        Array(5).fill(1).map((el, i) => {
                            if (i < rate) {
                                return <input key={i} type="radio" name={ratingKey} className="mask mask-star-2 bg-orange-400" checked onChange={() => { }} />
                            } else {
                                return <input key={i} type="radio" name={ratingKey} className="mask mask-star-2 bg-orange-400" />
                            }
                        }
                        )
                    }
                    <p className='ml-2 text-sm text-[#00000066] font-medium'>{rate} stars</p>
                </div>
            </div>

        </div>
    )
}

export default Reviews