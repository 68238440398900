import React from 'react'
import close from '../../assets/images/icons/close.svg'
import paypal from '../../assets/images/icons/paypal.svg'
import skrill from '../../assets/images/icons/skrill.svg'
import coinbase from '../../assets/images/icons/coinbase.svg'
import { closeModal, openModal } from '../modals/control'
export function TransactionDeclinedModal() {
    const declinedModalKey = "transaction_declined_modal"
    return (
        <div>
            <p className=' text-lg xs:text-base font-normal text-center '>
                If this window remains open after authorizing the purchase, <span className='text-[#E18A07] cursor-pointer'
                    onClick={() => openModal(declinedModalKey)}
                >click here to continue</span>
            </p>

            <dialog id={declinedModalKey} className="modal glass1">
                <div className="modal-box w-[957px] max-w-[957px] py-[60px] xs:py-12 px-[30px] xs:px-6 rounded-[5px] relative"
                    style={{ maxWidth: window.innerWidth > 650 ? "957px" : window.innerWidth - 50 }}
                >
                    <div className=' flex flex-row-reverse absolute top-5 right-5'>
                        <button className='focus-visible:outline-none'
                            onClick={() => closeModal(declinedModalKey)}
                        ><img src={close} alt="" /></button>
                    </div>
                    <div className='flex xs:flex-col xs:gap-y-8 justify-between'>
                        <h1 className='text-[22px] xs:text-lg font-bold mt-2'>Buy a package and enjoy the reddit power</h1>
                        <div className='flex items-center gap-x-6'>
                            <h3 className='text-lg xs:text-base font-normal'>You have 0 credits</h3>
                            <div className='w-[188px] h-10 flex justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                                <p className=' text-sm font-bold '>
                                    Contact Support
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='divider mx-20 xs:mx-0 mt-10 xs:mt-6'></div>
                    <div className='flex xs:flex-col xs:gap-y-4 justify-between items-center mx-20 xs:mx-0 mt-10 xs:mt-6'>
                        <h1 className='text-[35px] xs:text-2xl font-bold '>Transaction Declined</h1>
                        <div className='xs:flex xs:justify-between xs:items-center xs:w-full xs:mt-4'>
                            <h3 className='text-[20px] xs:text-base text-[#00000099] font-medium '>Current payment method</h3>
                            <div className='mt-5 xs:mt-0 w-[230px] xs:w-2/6 h-11 flex gap-x-4 justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                                <p className='text-sm font-bold '>
                                    Paypal
                                </p>
                            </div>
                        </div>
                    </div>
                    <h3 className='text-[22px] xs:text-lg font-bold text-center mt-14 xs:mt-7'>We could not process the payment</h3>
                    <p className='text-lg xs:text-base font-normal text-center mt-3'>For further information about the reason of the declined <br />transaction please contact our Billing Support Team.</p>
                    <div className='divider mx-20 xs:mx-0 mt-[60px] xs:mt-8'></div>
                    <p className='text-lg xs:text-base font-normal text-center mt-[60px] xs:mt-8'>Choose another type of payment or go to <span className='text-[#E18A07] cursor-pointer'

                    >Payment Methods</span></p>
                    <div className="mt-8 xs:mt-4 flex xs:flex-col justify-center items-center gap-x-5 w-full text-white">

                        <div className='mt-5 w-[230px] h-12 flex gap-x-4 justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                            <img src={paypal} alt="" className='rounded-full bg-white p-1 w-[34px] h-[34px]' />
                            <p className='text-sm font-bold '>
                                Paypal
                            </p>
                        </div>
                        <div className='mt-5 w-[230px] h-12 flex gap-x-4 justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                            <img src={skrill} alt="" />
                            <p className='text-sm font-bold '>
                                Skrill
                            </p>
                        </div>
                        <div className='mt-5 w-[230px] h-12 flex gap-x-4 justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                            <img src={coinbase} alt="" className='rounded-full' />
                            <p className='text-sm font-bold '>
                                Coinbase
                            </p>
                        </div>

                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default TransactionDeclinedModal