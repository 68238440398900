import React from 'react'
const data = [
    {
        source: "",
        date: "",
        orderId: "",
        type: "",
        transactionAmount: "",
        commission: "",
        earned: "",
        status: "",
    },

];
export function Table() {
    return (
        <div className='overflow-scroll'
            style={{ width: window.innerWidth > 650 ? "100%" : window.innerWidth - 100 }}
        >

            <table className="table xs:table-xs mt-10">
                {/* head */}
                <thead className='text-base font-normal '>
                    <tr className='text-center border-b-0'>
                        <th>Source</th>
                        <th>Date</th>
                        <th>Order ID</th>
                        <th>Type</th>
                        <th>Transaction amount</th>
                        <th>Commission (%)</th>
                        <th>Earned (USD)</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {/* data */}
                    {
                        data.map((item, index) => (
                            <tr key={index} className='border-b-0'>
                                <td className=''><p>{item.source}</p></td>
                                <td className=''>
                                    <p>{item.date}</p>
                                </td>
                                <td className=''>
                                    <p>{item.orderId}</p>
                                </td>
                                <td className=''>
                                    <p>{item.type}</p>
                                </td>
                                <td className=''>
                                    <p>{item.transactionAmount}</p>
                                </td>
                                <td className=''>
                                    <p>{item.commission}</p>
                                </td>
                                <td className=''>
                                    <p>{item.earned}</p>
                                </td>
                                <td className=''>
                                    <p>{item.status}</p>
                                </td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Table