import React from 'react'
import FilterBtn from './FilterBtn';
const data = [
    {
        date: "",
        trId: "",
        orderId: "",
        link: "",
        description: "",
        count: "",
        cost: "",
        balanceBefore: "",
        balanceAfter: "",
    },


];
export function TransactionsTable() {
    return (
        <div>

            <div className="overflow-x-auto bg-white mt-4 px-5 xs:px-1 pb-5 pt-2 rounded-[5px] min-h-screen">

                <div className='flex flex-row-reverse gap-x-4 mt-7'>
                    <div className='w-[120px] h-10 flex justify-center items-center px-10 py-2.5 rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                        <p className='text-sm font-bold '>
                            Export
                        </p>
                    </div>
                    <FilterBtn />

                </div>
                <div
                    className='overflow-scroll'
                    style={{ width: window.innerWidth > 650 ? "100%" : window.innerWidth - 110 }}>

                    <table className="table mt-10">
                        {/* head */}
                        <thead className='text-base font-normal text-black'>
                            <tr className='text-center'>
                                <th>Date</th>
                                <th>Transaction ID</th>
                                <th>Order ID</th>
                                <th>Link</th>
                                <th>Description</th>
                                <th>Count</th>
                                <th>Cost</th>
                                <th>Balance Before</th>
                                <th>Balance After</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* data */}
                            {
                                Array(50).fill(1).map((el, i) =>
                                    data.map((item, index) => (
                                        <tr key={index} className='border-b-0'>
                                            <td className='border-r-[1px]'><p>{item.date}</p></td>
                                            <td className='border-r-[1px]'>
                                                <p>{item.trId}</p>
                                            </td>
                                            <td className='border-r-[1px]'>
                                                <p>{item.orderId}</p>
                                            </td>
                                            <td className='border-r-[1px]'>
                                                <p>{item.link}</p>
                                            </td>
                                            <td className='border-r-[1px]'>
                                                <p>{item.description}</p>
                                            </td>
                                            <td className='border-r-[1px]'>
                                                <p>{item.count}</p>
                                            </td>
                                            <td className='border-r-[1px]'>
                                                <p>{item.cost}</p>
                                            </td>
                                            <td className='border-r-[1px]'>
                                                <p>{item.balanceBefore}</p>
                                            </td>
                                            <td className=''>
                                                <p>{item.balanceAfter}</p>
                                            </td>
                                        </tr>
                                    ))
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TransactionsTable