import React from 'react'

function CustomAccordion({ id = "my-accordion", q, Ans }) {
    // const [toggle, setToggle] = useState("")
    return (
        <div className={`collapse collapse-plus border-b-[1px] rounded-none !cursor-pointer`}
            onClick={() => {
                // setToggle(prev => {
                //     if (prev === "") {
                //         return "collapse-open"
                //     } else if (prev === "collapse-close") {
                //         return ""
                //         // return "collapse-open"
                //     } else if (prev === "collapse-open") {
                //         return "collapse-close"
                //     }
                // })
            }}
        >
            <input type="radio" name={id} />
            <div className="collapse-title text-xl xs:text-base font-bold pl-0"

            >
                {q}
            </div>
            <div className="collapse-content w-5/6 xs:w-11/12 text-lg xs:text-sm font-normal text-[#00000099] pl-0">
                <Ans />
            </div>
        </div>
    )
}

export default CustomAccordion