import React from 'react'
import close from '../../assets/images/icons/close.svg'
import doller from '../../assets/images/icons/doller.svg'
import WithdrawalMethod from './WithdrawalMethod'
import { closeModal } from '../modals/control'
export function WithdrawalModal() {
    // const { pathname } = useLocation()
    // useEffect(() => {
    //     if (pathname === "/withdrawal-modal") {
    //         document.getElementById('withdrawal_modal').showModal()
    //     }
    // }, [pathname])
    return (
        <div>
            <div className='xs:w-full xs:flex xs:justify-center'>

                <div className='mt-11 w-[210px] h-10 flex justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424]  cursor-pointer'
                    onClick={() => document.getElementById('withdrawal_modal').showModal()}
                >
                    <p className='text-sm font-bold '>
                        Apply for withdrawn
                    </p>
                </div>
            </div>
            <dialog id="withdrawal_modal" className="modal">
                <div className="modal-box w-[550px] max-w-[550px] py-[30px] px-[35px] rounded-[5px] relative">
                    <div className=' flex flex-row-reverse absolute top-5 right-5'>
                        <button className='focus-visible:outline-none'
                            onClick={() => closeModal("withdrawal_modal")}
                        ><img src={close} alt="" /></button>
                    </div>
                    <h1 className='text-[35px] font-bold text-center mt-2'>Apply for withdrawal</h1>
                    <div className='flex justify-between items-end mt-8'>
                        <div className=''>

                            <label className="label">
                                <span className="text-xl font-medium text-[#00000099]">Withdrawal currency*</span>
                            </label>
                            <select defaultValue="usd" className="select text-start text-lg text-[#21262999] font-normal bg-transparent border rounded-[6px] !border-[#21262933] w-[216px] h-[52px] min-h-[30px]">
                                <option value="usd">USD</option>
                                <option>EU</option>
                                <option>BTC</option>
                            </select>
                            {/* <input type="text" placeholder="USD" className="input input-bordered w-[216px] h-[52px] bg-transparent" /> */}
                        </div>
                        <div className='mt-11 w-[110px] h-12 flex justify-center items-center rounded-[6px] bg-[#F90]  cursor-pointer'>
                            <p className='text-white text-sm font-bold '>
                                Balance: $0
                            </p>
                        </div>
                    </div>
                    <div className='mt-11'>
                        <label className="label">
                            <span className="text-xl font-medium text-[#00000099]">Withdrawal amount*</span>
                        </label>
                        <div className='flex items-center pl-4 w-[415px] h-[52px] border border-[#21262933] rounded-md bg-transparent'>
                            <img src={doller} alt="" className='w-5 h-5' />
                            <input type="text" placeholder="50" className="input focus:outline-none focus-within:border-0" />
                        </div>
                    </div>
                    <WithdrawalMethod />
                    <div className='mt-11'>
                        <label className="label">
                            <span className="text-xl font-medium text-[#00000099]">Email*</span>
                        </label>
                        <input type="email" placeholder="Email address" className="input input-bordered w-[415px] h-[52px] bg-transparent" />
                    </div>
                    <div className="flex justify-center items-center w-full">
                        <div className='mt-11 w-[415px] h-12 flex justify-center items-center rounded-[20px] bg-[#F90] cursor-pointer'>
                            <p className='text-white text-sm font-bold '>
                                Submit
                            </p>
                        </div>

                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default WithdrawalModal