import React from 'react'

function CostItem({ text, icon }) {
    return (
        <div className='flex items-center'>
            <img src={icon} alt="" className='mr-12 w-[30px] h-[30px]' />
            <div className='w-[12px] h-[10px] bg-[#242424] -rotate-45'></div>
            <div className='ml-6 flex justify-between w-full'>
                <h4>{text}</h4>
                <h4>= 1 Credit</h4>
            </div>
        </div>
    )
}

export default CostItem