import React from 'react'
import BalanceCard from '../components/newOrder/BalanceCard'
import { OrderForm } from '../components/newOrder'

export function NewOrder() {
    return (
        <div>
            <h1 className='text-2xl xs:text-xl font-bold'>Place New Order</h1>
            <div className='mt-14 xs:mt-7'>
                <BalanceCard />
            </div>
            <div className='mt-7'>
                <OrderForm />
            </div>

        </div>
    )
}

export default NewOrder