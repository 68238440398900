import React from 'react'
import banner from '../../assets/images/banner-1.png'
import reddit from '../../assets/images/reddit.svg'
export function Banner() {
    return (
        <div className="w-full flex justify-center ">
            <div className="relative w-full pr-10 xs:pr-2 ">
                <img src={banner} alt="banner" className="object-cover w-full" />
                <div className="absolute top-0 w-full h-full">
                    <div className="flex justify-center items-center h-full">
                        <h1 className="text-white text-[45px] xs:text-2xl xs:text-center font-extrabold">
                            $0.015 / VOTE ALL PACKAGE NOW!
                        </h1>
                    </div>
                </div>
                <div className="absolute bottom-[-40px]  right-[-20px] xs:hidden">
                    <img src={reddit} alt="reddit" className='xs:w-2/6 ' />
                </div>
            </div>
        </div>
    )
}

export default Banner