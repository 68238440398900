import React, { useState } from 'react'
import { Sidebar, Menu } from 'react-pro-sidebar';
import Logo from './Logo';
import MainItem from './MainItem';
import Item from './Item';
// icons
import home from '../../assets/images/icons/default.svg'
import homeWhite from '../../assets/images/icons/default-white.svg'
import newOrder from '../../assets/images/icons/newOrder.svg'
import newOrderWhite from '../../assets/images/icons/newOrder-white.svg'
import deposit from '../../assets/images/icons/deposit.svg'
import depositWhite from '../../assets/images/icons/deposit-white.svg'
import transactions from '../../assets/images/icons/trasaction.svg'
import transactionsWhite from '../../assets/images/icons/trasaction-white.svg'
import guide from '../../assets/images/icons/Guide.svg'
import guideWhite from '../../assets/images/icons/Guide-white.svg'
import customer from '../../assets/images/icons/customer.svg'
import customerWhite from '../../assets/images/icons/customer-white.svg'
import dollar from '../../assets/images/icons/dollar.svg'
import dollarWhite from '../../assets/images/icons/dollar-white.svg'
import settings from '../../assets/images/icons/settings.svg'
import settingsWhite from '../../assets/images/icons/settings-white.svg'
import logout from '../../assets/images/icons/logout.svg'
import faq from '../../assets/images/icons/faq.svg'
import faqWhite from '../../assets/images/icons/faq-white.svg'
import telegram from '../../assets/images/icons/telegram.svg'
import telegramWhite from '../../assets/images/icons/telegram-white.svg'
import whatsapp from '../../assets/images/icons/whatsapp.svg'
import whatsappWhite from '../../assets/images/icons/whatsapp-white.svg'
function SideBarCustom() {
    const [collapsed, setCollapsed] = useState(true);
    return (
        <Sidebar
            collapsed={collapsed}
            // backgroundColor="white"
            // h-fit / screen
            className=' rounded-r-[20px] scrollbar-hide '
            collapsedWidth="75px"
            width="275px"
        >
            <Logo collapsed={collapsed} setCollapsed={setCollapsed} />
            <Menu closeOnClick={true}
                className='pt-8 pb-5 '
            >
                <MainItem collapsed={collapsed} name={"Services"} />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={home} activeIcon={homeWhite} name={"Dashboard"} path="dashboard" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={newOrder} activeIcon={newOrderWhite} name={"New Order"} path="new-order" />
                <MainItem collapsed={collapsed} name={"User"} />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={deposit} activeIcon={depositWhite} name={"Top Up / Deposit"} path="deposit" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={transactions} activeIcon={transactionsWhite} name={"Transactions"} path="transactions" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={guide} activeIcon={guideWhite} name={"Usage Guide"} path="usage-guide" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={customer} activeIcon={customerWhite} name={"Customer range"} path="customer-range" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={dollar} activeIcon={dollarWhite} name={"Affiliate"} path="affiliate" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={settings} activeIcon={settingsWhite} name={"Settings"} path="settings" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={logout} activeIcon={logout} name={"Logout"} path="logout" />
                <MainItem collapsed={collapsed} name={"Support"} />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={faq} activeIcon={faqWhite} name={"FAQ’s"} path="faq" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={telegram} activeIcon={telegramWhite} name={"Contact (Telegram)"} path="contact-telegram" />
                <Item collapsed={collapsed} setCollapsed={setCollapsed} icon={whatsapp} activeIcon={whatsappWhite} name={"Contact (Whatsapp)"} path="contact-whatsapp" />


            </Menu>
        </Sidebar>
    )
}

export default SideBarCustom