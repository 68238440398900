import React from 'react'
import close from '../../assets/images/icons/close.svg'
import VerifyCardModal from './VerifyCardModal'
import { closeModal, openModal } from '../modals/control'
export function CardMethodModal() {
    const carkModalKey = "card_modal"
    return (
        <div>
            <div className=' w-[415px] xs:w-full h-12 flex justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'
                onClick={() => {
                    openModal(carkModalKey)
                }}
            >
                <p className=' text-sm font-bold '>
                    Debit / Credit Card (Recommended)
                </p>
            </div>

            <dialog id={carkModalKey} className="modal glass1">
                <div className="modal-box w-[803px] max-w-[803px] py-[60px] px-[30px] rounded-[5px] relative"
                    style={{ maxWidth: window.innerWidth > 650 ? "803px" : window.innerWidth - 50 }}
                >
                    <div className=' flex flex-row-reverse absolute top-5 right-5'>
                        <button className='focus-visible:outline-none'
                            onClick={() => closeModal(carkModalKey)}
                        ><img src={close} alt="" /></button>
                    </div>
                    <div className='flex gap-x-16 xs:gap-x-8 xs:justify-between text-[22px] xs:text-lg font-bold'>
                        <h1 className=''>1. Loreum ipsum</h1>
                        <h1 className='text-[#E18A07]'>2. Loreum ipsum</h1>

                    </div>
                    <div className='divider divider-start text-lg xs:text-base font-normal mt-8 mx-5 xs:mx-0'>Customer Data</div>
                    <div className='mx-5 xs:mx-0 mt-7 xs:mt-4 flex xs:flex-col xs:gap-y-4 gap-x-4'>
                        <div className=''>
                            <label className="label">
                                <span className="text-xl xs:text-base font-medium text-[#00000099]">First Name*</span>
                            </label>
                            <input type="text" placeholder="First Name" className="input input-bordered w-[266px] xs:w-full h-[52px] bg-transparent" />
                        </div>
                        <div className=''>
                            <label className="label">
                                <span className="text-xl xs:text-base font-medium text-[#00000099]">Last Name*</span>
                            </label>
                            <input type="text" placeholder="Last Name" className="input input-bordered w-[266px] xs:w-full h-[52px] bg-transparent" />
                        </div>
                    </div>
                    <div className='mx-5 xs:mx-0 mt-7 xs:mt-4 flex gap-x-4'>
                        <div className=''>
                            <label className="label">
                                <span className="text-xl xs:text-base font-medium text-[#00000099]">Pais</span>
                            </label>
                            <input type="text" placeholder="Loreum ipsum" className="input input-bordered w-[266px] xs:w-full h-[52px] bg-transparent" />
                        </div>
                        <div className=''>
                            <label className="label">
                                <span className="text-xl xs:text-base font-medium text-[#00000099]">Postal Code</span>
                            </label>
                            <input type="text" placeholder="Enter your code" className="input input-bordered w-[266px] xs:w-full h-[52px] bg-transparent" />
                        </div>
                    </div>

                    <div className='divider divider-start text-lg xs:text-base font-normal mt-8 mx-5 xs:mx-0'>Payment Details</div>
                    <div className=' mt-7 flex xs:grid xs:grid-cols-2 xs:gap-y-4 gap-x-4'>
                        <div className='flex gap-x-2 xs:col-span-2'>
                            <label className="label">
                                <span className="text-xl xs:text-sm font-medium text-[#00000099]">Card number</span>
                            </label>
                            <input type="text" placeholder="xxxx xxxx xxxx xxxx" className="input input-bordered w-[166px] xs:w-full h-[52px] bg-transparent" />
                        </div>
                        <div className='flex gap-x-2'>
                            <label className="label">
                                <span className="text-xl xs:text-sm font-medium text-[#00000099]">Exp Date</span>
                            </label>
                            <input type="text" placeholder="mm/yy" className="input input-bordered w-[123px] xs:w-full h-[52px] bg-transparent" />
                        </div>
                        <div className='flex gap-x-2'>
                            <label className="label">
                                <span className="text-xl xs:text-sm font-medium text-[#00000099]">CVV</span>
                            </label>
                            <input type="text" placeholder="CVV code" className="input input-bordered w-[128px] xs:w-full h-[52px] bg-transparent" />
                        </div>
                    </div>
                    <div className="mt-10 flex justify-center items-center w-full">
                        <VerifyCardModal />

                    </div>

                    <p className='mt-12 text-lg xs:text-base font-normal text-center '>The charges will appear in your "Transactions" section</p>
                    <p className='mt-4 text-xs font-medium text-center '>Important: Este es un cargo instantáneo único. La información confidencial de tu tarjeta NO se almacena en nuestro sistema.</p>

                </div>
            </dialog>
        </div>
    )
}

export default CardMethodModal