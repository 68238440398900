import React from 'react'
import close from '../../assets/images/icons/close.svg'
import paypal from '../../assets/images/icons/paypal.svg'
import skrill from '../../assets/images/icons/skrill.svg'
import coinbase from '../../assets/images/icons/coinbase.svg'
import CardMethodModal from './CardMethodModal'
import { closeModal } from '../modals/control'
export function PaymentModal({ active }) {
    const PaymentModalKey = "payments_modal"
    return (
        <div className=''>
            {/* {
                active ?
                    <div className='btn flex justify-center items-center bg-black mt-6 w-[231px] h-10 border rounded-[20px] border-solid border-[#242424] cursor-pointer'
                        onClick={() => openModal(PaymentModalKey)}
                    >
                        <p className='text-sm font-bold text-white'>Buy Now</p>
                    </div>
                    :
                    <div className='btn flex justify-center items-center bg-transparent mt-6 w-[231px] h-10 border rounded-[20px] border-solid border-[#242424] cursor-pointer'
                        onClick={() => openModal(PaymentModalKey)}
                    >
                        <p className='text-sm font-bold'>Buy Now</p>
                    </div>

            } */}

            <dialog id={PaymentModalKey} className="modal glass1">
                <div className="modal-box w-[773px] max-w-[773px] py-[60px] px-[30px] rounded-[5px] relative"
                    style={{ maxWidth: window.innerWidth > 650 ? "733px" : window.innerWidth - 50 }}
                >
                    <div className=' flex flex-row-reverse absolute top-5 right-5'>
                        <button className='focus-visible:outline-none'
                            onClick={() => closeModal(PaymentModalKey)}
                        ><img src={close} alt="" /></button>
                    </div>
                    <div className='flex xs:flex-col xs:gap-y-5 justify-between'>
                        <h1 className='text-[35px] xs:text-2xl font-bold mt-2'><span className='text-[#E18A07]'>UPVOTE</span> STORE</h1>
                        <div className='flex items-center gap-x-6'>
                            <h3 className='text-lg xs:text-base font-normal'>You have 0 credits</h3>
                            <div className='w-[188px] h-10 flex justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                                <p className=' text-sm font-bold '>
                                    Contact Support
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <h1 className='text-[22px] xs:text-lg font-bold '>1. Payment Method</h1>
                    <div className='divider'></div>
                    <p className='text-lg xs:text-base font-normal text-center '>Please choose one of the following payment method</p>
                    <div className="mt-10 flex justify-center items-center w-full ">
                        <div className='xs:w-full'>
                            <CardMethodModal />
                            <div className='mt-5 w-[415px] xs:w-full  h-12 flex gap-x-4 justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                                <img src={paypal} alt="" />
                                <p className='text-sm font-bold '>
                                    Paypal
                                </p>
                            </div>
                            <div className='mt-5 w-[415px] xs:w-full h-12 flex gap-x-4 justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                                <img src={skrill} alt="" />
                                <p className='text-sm font-bold '>
                                    Skrill
                                </p>
                            </div>
                            <div className='mt-5 w-[415px] xs:w-full h-12 flex gap-x-4 justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                                <img src={coinbase} alt="" />
                                <p className='text-sm font-bold '>
                                    Coinbase
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default PaymentModal