import React from 'react'
import img404 from '../assets/images/icons/404.svg'
export function Error404() {
    return (
        <div className='w-full h-screen'>
            <div className='flex flex-col justify-center items-center text-center xs:pt-40'>
                <img src={img404} alt="" className='w-52 xs:w-28 h-[375px] xs:h-44' />
                <h1 className='text-[35px] xs:text-xl font-bold'>Error 404!</h1>
                <p className='text-2xl xs:text-lg font-normal text-[#00000099]'>Reload the website or wait few minutes for page to load</p>
            </div>

        </div>
    )
}

export default Error404