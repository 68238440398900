import React from 'react'
import { Reviews, PlanCard, PaymentModal } from '../components/home'
import check from '../assets/images/icons/check.svg'
import arrowUp from '../assets/images/icons/UpvoteIcon_1.svg'
import commentUpvotes from '../assets/images/icons/CommentUpvotes.svg'
import commentDownvotes from '../assets/images/icons/CommentDownvotes.svg'
import arrowDown from '../assets/images/icons/arrow-down.svg'
import send from '../assets/images/icons/MassDm.svg'
import followers from '../assets/images/icons/Follower.svg'
import user from '../assets/images/icons/user.svg'
export function Home() {
    return (
        <div className='mr-20 xs:mr-0'>
            <h1 className='text-center text-[43px] xs:text-2xl text-[#E18A07] font-extrabold'>
                Trying to dominate and monetize Reddit?</h1>
            <h2 className='text-center text-[30px] xs:text-xl xs:mt-4 font-bold'>Here’s The Perfect Solution!</h2>
            <div className='mt-16 xs:mt-8 flex justify-center gap-x-10 xs:gap-x-2 text-[#00000099] text-xl xs:text-base font-medium'>
                <ul className=''>
                    <li className='flex items-start gap-x-[6px]'><img src={check} alt="" /> 100% Money Back Guarantee</li>
                    <li className='flex items-start  gap-x-[6px] text-center xs:text-left mt-5'><img src={check} alt="" className='pt-1' />Customizable orders without <br /> limits</li>
                </ul>
                <ul className=''>
                    <li className='flex items-start gap-x-[6px]'><img src={check} alt="" />The most absurd prices on the market</li>
                    <li className='flex items-start gap-x-[6px] mt-5'><img src={check} alt="" />High profitability</li>
                </ul>
            </div>
            <div className='w-full flex flex-col justify-start items-center '>
                <div className='mt-16 flex xs:flex-col xs:gap-y-10 gap-x-10 '>
                    <PlanCard icon={arrowUp} title="Upvotes" features={["0.15$ / Upvote", "Instantly", "Custom speed"]} />
                    <PlanCard icon={arrowDown} title="Downvotes" features={["0.15$ / Upvote", "Instantly", "Custom speed"]} />
                    <PlanCard icon={commentUpvotes} title="Comment Upvotes" features={["0.15$ / Upvote", "Instantly", "Custom speed"]} />
                </div>
                <div className='mt-8 flex xs:flex-col xs:gap-y-10 gap-x-10'>
                    <PlanCard icon={commentDownvotes} title="Comment Downvotes" features={["0.15$ / Upvote", "Instantly", "Custom speed"]} />
                    <PlanCard icon={send} title="Mass DM" features={["0.15$ / Upvote", "Instantly", "Custom speed"]} />
                    <PlanCard icon={followers} title="Followers" features={["0.15$ / Upvote", "Instantly", "Custom speed"]} />
                </div>

            </div>
            <div className="divider divider-start after:bg-black mt-20 xs:mt-10"><h1 className='text-2xl xs:text-xl font-bold'>Customer Reviews</h1></div>
            <div className='mt-16 xs:mt-8 flex flex-col gap-y-[60px]'>
                <Reviews img={user} name="Lisa Grey" date="3 weeks ago" content="Lorem ipsum dolor sit amet consectetur. Mauris auctor dolor venenatis suspendisse. Condimentum ac convallis netus dui et ac imperdiet cras. Scelerisque sit hendrerit ut lorem malesuada ut turpis egestas. Viverra vehicula fermentum sit massa ullamcorper quisque viverra. Lobortis morbi semper nisl in." rate={4} />
                <Reviews img={user} name="Lisa Grey" date="3 weeks ago" content="Lorem ipsum dolor sit amet consectetur. Mauris auctor dolor venenatis suspendisse. Condimentum ac convallis netus dui et ac imperdiet cras. Scelerisque sit hendrerit ut lorem malesuada ut turpis egestas. Viverra vehicula fermentum sit massa ullamcorper quisque viverra. Lobortis morbi semper nisl in." rate={3} />
                <Reviews img={user} name="Lisa Grey" date="3 weeks ago" content="Lorem ipsum dolor sit amet consectetur. Mauris auctor dolor venenatis suspendisse. Condimentum ac convallis netus dui et ac imperdiet cras. Scelerisque sit hendrerit ut lorem malesuada ut turpis egestas. Viverra vehicula fermentum sit massa ullamcorper quisque viverra. Lobortis morbi semper nisl in." rate={5} />
            </div>
            <div className="divider divider-start after:bg-black mt-20"><h1 className='text-2xl xs:text-xl font-bold'>Affiliate Reviews</h1></div>
            <div className='mt-16 xs:mt-8 flex flex-col gap-y-[60px]'>
                <Reviews img={user} name="Lisa Grey" date="3 weeks ago" content="Lorem ipsum dolor sit amet consectetur. Mauris auctor dolor venenatis suspendisse. Condimentum ac convallis netus dui et ac imperdiet cras. Scelerisque sit hendrerit ut lorem malesuada ut turpis egestas. Viverra vehicula fermentum sit massa ullamcorper quisque viverra. Lobortis morbi semper nisl in." rate={3} />
                <Reviews img={user} name="Lisa Grey" date="3 weeks ago" content="Lorem ipsum dolor sit amet consectetur. Mauris auctor dolor venenatis suspendisse. Condimentum ac convallis netus dui et ac imperdiet cras. Scelerisque sit hendrerit ut lorem malesuada ut turpis egestas. Viverra vehicula fermentum sit massa ullamcorper quisque viverra. Lobortis morbi semper nisl in." rate={4} />
            </div>
            {/* Modals */}
            <PaymentModal />
        </div>
    )
}

export default Home