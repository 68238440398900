import React from 'react'
export function FilterBtn() {
    return (
        <select defaultValue="data" className="select text-center text-sm text-[#242424] font-bold bg-transparent border rounded-[20px] !border-[#242424] w-[120px] h-10 min-h-[30px]">
            <option value="data">Date</option>
            <option>Date 1</option>
            <option>Date 2</option>
        </select>
    )
}

export default FilterBtn