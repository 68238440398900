import React from 'react'
import logo from '../../assets/images/icons/logo.svg'
import { Link } from 'react-router-dom'
import logo_name from '../../assets/images/icons/logo-name.svg'
function Logo({ collapsed, setCollapsed }) {
    return (
        <Link to="/" className='cursor-pointer' onClick={() => {
            if (window.innerWidth > 650) {
                setCollapsed(!collapsed)
            } else {
                setCollapsed(true)

            }
        }}
        >
            {
                collapsed ?
                    <div className="flex justify-center" >
                        <img className="pt-10" src={logo} alt='logo'
                            width={23}
                            height={26}
                        />
                    </div>
                    :
                    <div className="">

                        <img className="ml-[30px] pt-10" src={logo_name} alt='logo'
                            // width={36}
                            // height={36}
                            width={210}
                            height={26}
                        />
                    </div>
            }
        </Link>
    )
}

export default Logo