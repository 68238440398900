import React from 'react'
import robot from '../../assets/images/icons/robot.svg'
import CostItem from './CostItem'
import upvoteIcon from '../../assets/images/icons/UpvoteIcon.svg'
import downvote from '../../assets/images/icons/Downvote.svg'
import massDM from '../../assets/images/icons/MassDM.svg'
import commentUpvote from '../../assets/images/icons/CommentUpvote.svg'
import commentDownvote from '../../assets/images/icons/CommentDownvote.svg'
import followerIcon from '../../assets/images/icons/FollowerIcon.svg'

export function OrderForm() {
    return (
        <div className='card bg-base-100 w-[900px]  pt-5 pb-10 rounded-[5px]'
            style={{ width: window.innerWidth > 650 ? "900" : window.innerWidth - 100 }}
        >
            <div className="form-control px-6">
                <div className='flex xs:flex-col xs:gap-y-4 justify-between gap-x-5 '>
                    <div className='w-full'>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">Services</span>
                        </label>
                        <input type="text" placeholder="Email address" className="input input-bordered w-full h-[52px]" />
                    </div>
                    <div className='w-full'>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">Custom Soeed</span>
                        </label>
                        <input type="text" placeholder="Email address" className="input input-bordered w-full h-[52px]" />
                    </div>
                </div>
                <div className='w-full mt-8 xs:mt-4'>
                    <label className="label">
                        <span className="text-xl xs:text-lg font-medium text-[#00000099]">Past URL</span>
                    </label>
                    <input type="text" placeholder="Email address" className="input input-bordered w-full h-[52px]" />
                </div>
                <div className='w-full mt-8 xs:mt-4'>
                    <label className="label">
                        <span className="text-xl xs:text-lg font-medium text-[#00000099]">How many votes do you want to send (Max 500 upvotes/post)</span>
                    </label>
                    <input type="text" placeholder="Email address" className="input input-bordered w-full h-[52px]" />
                </div>
                {/*  */}
                <div className='mt-2'>
                    <label className="label justify-start cursor-pointer">
                        <input type="radio" name="radio-10" className="radio radio-xs mr-3 checked:bg-black" />
                        <span className="text-lg xs:text-base text-[#21262999] font-normal">Deliver Immediately</span>
                    </label>
                    <label className="label justify-start cursor-pointer">
                        <input type="radio" name="radio-10" className="radio radio-xs mr-3 checked:bg-black" />
                        <span className="text-lg xs:text-base text-[#21262999] font-normal">Deliver after 10 minutes</span>
                    </label>
                    <label className="label justify-start cursor-pointer">
                        <input type="radio" name="radio-10" className="radio radio-xs mr-3 checked:bg-black" />
                        <span className="text-lg xs:text-base text-[#21262999] font-normal">Deliver 30 minutes</span>
                    </label>
                    <label className="label justify-start cursor-pointer">
                        <input type="radio" name="radio-10" className="radio radio-xs mr-3 checked:bg-black" />
                        <span className="text-lg xs:text-base text-[#21262999] font-normal">Deliver after 1 Hour</span>
                    </label>
                    <label className="label justify-start cursor-pointer">
                        <input type="radio" name="radio-10" className="radio radio-xs mr-3 checked:bg-black" />
                        <input type="number" placeholder="Custom time" className="input input-bordered w-36 h-[42px]" />
                        <span className="text-lg xs:text-base pt-3 pl-1 text-[#21262999] font-normal">(minutes)</span>
                    </label>
                </div>
            </div>
            <div className='px-6'>

                <h1 className='mt-12 xs:mt-6 text-2xl xs:text-xl font-bold'>Total Cost : </h1>
                <div className='mt-4 w-[260px] h-[44px] flex justify-center items-center px-10 py-2.5 rounded-[20px] bg-[#242424] cursor-pointer'>
                    <img src={robot} alt="" className='mr-2' />
                    <p className='text-white text-base font-bold '>
                        Place Order
                    </p>
                </div>
            </div>
            <div className="divider mt-10 mb-8 xs:mb-4"></div>
            <div className='px-6'>

                <h2 className='text-xl xs:text-lg text-[#00000099] font-extrabold'>MENU PRICES - COST OF 1 PRODUCT WITH CREDITS</h2>
                <div className='flex flex-col w-6/12 xs:w-full gap-y-6 mt-5 text-lg xs:text-base text-[#21262999] font-semibold'>

                    <CostItem text="1 Upvote" icon={upvoteIcon} />
                    <CostItem text="1 Downvote" icon={downvote} />
                    <CostItem text="1 Mass DM Message" icon={massDM} />
                    <CostItem text="1 Comment Upvote" icon={commentUpvote} />
                    <CostItem text="1 Comment Downvote" icon={commentDownvote} />
                    <CostItem text="1 Follower" icon={followerIcon} />

                </div>
            </div>
        </div>
    )
}

export default OrderForm