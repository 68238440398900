import React from 'react'
import CustomAccordion from '../components/faq/CustomAccordion'
import { Link } from 'react-router-dom'
export function Faq() {
    return (
        <div className='w-[730px] xs:w-full'>
            <h1 className='text-2xl xs:text-xl font-bold'>Reddit Panel FAQ</h1>
            <p className='text-lg xs:text-base xs:mt-3 text-[#00000099] font-medium'>Here are some of the most asked questions for people using <br />our Reddit Panel on Upvote Store.</p>
            <div className='mt-6 w-[170px] h-10 flex justify-center items-center px-10 py-2.5 rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424]  cursor-pointer'>
                <p className=' text-sm font-bold '>
                    Access Panel
                </p>
            </div>
            {/* Deposit */}
            <h2 className='mt-14 ml-[10px] text-2xl xs:text-xl text-[#E18A07] font-bold'>Deposit</h2>
            <div className='mt-[10px] divider before:bg-[#E18A07] after:bg-[#E18A07]'></div>
            <CustomAccordion id='deposit' q="What payment methods are supported?"
                Ans={() =>
                    <ul className='list-disc pl-4'>
                        <li>Credit/Debit Card</li>
                        <li>Skrill</li>
                        <li>CoinBase / Crypto: We support most tokens of popular <br />networks like ERC20, BTC, LTC, TRC20, BEP20,</li>
                        <li>Other (Please contact)</li>
                    </ul>
                }
            />
            <CustomAccordion id='deposit' q="Can I pay with another method? Such as PayPal"
                Ans={() =>
                    <p>Yes, we can support many other manual payment methods. Please contact us before sending the payments.</p>
                }
            />
            <CustomAccordion id='deposit' q="I’ve just deposited, and the balance still hasn’t been added yet"
                Ans={() =>
                    <ul className='list-disc pl-4'>
                        <li>Cryptocurrency: Normally, the balance will be added to your account after 10-30 minutes since it takes time for the blockchain to confirm your transactions. However, sometimes the blockchain could get slower due to the high amount of transactions. In this case,</li>
                        <li>Credit/Debit Card: The balance will be almost always added to your account immediately.</li>
                        <li>If the balance does not get added to your account within 30 minutes, please <Link to="/contact" className='text-[#E18A07]'>contact us</Link> to get it solved.</li>
                    </ul>
                }
            />
            {/* Orders */}
            <h2 className='mt-20 xs:mt-10 ml-[10px] text-2xl xs:text-xl text-[#E18A07] font-bold'>Orders</h2>
            <div className='mt-[10px] divider before:bg-[#E18A07] after:bg-[#E18A07]'></div>
            <CustomAccordion id='orders' q="How to order?"
                Ans={() =>
                    <ul className='list-disc pl-4'>
                        <li><span className='text-black font-semibold'>STEP 1</span> - Register an account at <a href="https://panel.upvote.store" className='text-[#E18A07]'>https://panel.upvote.store</a></li>
                        <li><span className='text-black font-semibold'>STEP 2</span> - Recharge the balance at: <a href="https://panel.upvote.store/deposit" className='text-[#E18A07]'>https://panel.upvote.store/deposit</a></li>
                        <li><span className='text-black font-semibold'>STEP 3</span> - Then you can use the balance to place an order.</li>
                    </ul>
                }
            />
            <CustomAccordion id='orders' q="When do you start sending upvotes/downvotes?"
                Ans={() =>
                    <ul className='list-disc pl-4'>
                        <li>We almost always start sending the votes immediately after you place the order, unless you have scheduled delivery not to begin immediately</li>
                        <li>Our system is fully automated, so we don’t need to be online to process your order.</li>
                    </ul>
                }
            />
            <CustomAccordion id='orders' q="What are the order statuses?"
                Ans={() =>
                    <ul className='list-disc pl-4'>
                        <li>Processing: The order is being processed by the system. In this status, order will not be able to cancel.</li>
                        <li>Finished: The order has been completed.</li>
                        <li>Active after xx mins: Scheduling orders, which will start at the time you set the schedule. (You can cancel before the order starts by clicking on the Action box)</li>
                        <li>Canceled: Orders are canceled due to some reasons such as post/comment does not exist, missing upvote, manual cancellation of scheduled orders,… All these orders will be automatically checked by the system and refunded credits = upvotes have not been sent.</li>
                        <li>Deleted: The post is deleted by mods or Reddit (maybe the account has been shadowbanned), the order will be canceled, and the system will refund the credits = the upvotes that have not been sent.</li>
                    </ul>
                }
            />
            <CustomAccordion id='orders' q="What types of accounts do you use for upvotes, downvotes, and comments?"
                Ans={() =>
                    <p>The accounts we use are hand-grown aged accounts with post history and high karma.</p>
                }
            />
            <CustomAccordion id='orders' q="Can My Account Gets Shadowbanned or Banned from the Reddit System?"
                Ans={() =>
                    <>
                        <p>As far as we have experienced, there is no ban our users have received. However, you want to make sure that <span className='text-[#E18A07] underline'>your submissions are not obvious spam.</span></p>
                        <p>For example, you cannot just go and program to receive more than 50 Upvotes without having the post less than 1 Hour old. That’s detected as upvote manipulation quickly</p>
                        <p>You want to approach it naturally for not only getting banned by the Reddit anti-spam system against fake votes manipulation, but also to gain more real converts from the community.</p>
                    </>
                }
            />
            <CustomAccordion id='orders' q="Can you give me some advice for my Reddit marketing campaign?"
                Ans={() =>
                    <p>That’s what we always love to do! Feel free to ask us any questions, and we will always do our best to help you out! Also, check out our <span className='text-[#E18A07] underline'>blog</span> for more <span className='text-[#E18A07] underline'>Reddit Marketing guides!</span></p>
                }
            />
            <CustomAccordion id='orders' q="Should I send upvotes to posts that are more than 1 day old?"
                Ans={() =>
                    <>
                        <p>Yes, there is no issue at all when using our votes.</p>
                        <p>However, do note that due to the way this social media works, your post will not get pushed to the hot section unless there are fewer than 10 new posts within that subreddit in 24 hours. Reddit always pushes the older posts to the next pages, so people are always engaged in creating new content.</p>
                        <p>But, boosting engagement and a lot of <span className='text-[#E18A07] underline'>upvotes</span> to a post could bring it to the Top section, which is a great strategy for long-term projects.</p>
                        <p className='pt-10'>Read more: What is <span className='text-[#E18A07] underline'>Reddit Upvote & How it works</span></p>
                    </>
                }
            />
            <CustomAccordion id='orders' q="How to buy Reddit upvotes again?"
                Ans={() =>
                    <p className='pl-0'>Get to our panel, and you will see an option allowing you to duplicate your order with any selected numbers of votes. This works flawlessly with both post and comment option.</p>
                }
            />
            {/* Policy */}
            <h2 className='mt-20 xs:mt-10 ml-[10px] text-2xl xs:text-xl text-[#E18A07] font-bold'>Policy</h2>
            <div className='mt-[10px] divider before:bg-[#E18A07] after:bg-[#E18A07]'></div>
            <CustomAccordion id='policy' q="What is the Refund Policy?"
                Ans={() =>
                    <ul className='list-disc pl-4'>
                        <li>We only charge for work done. All upvotes/downvotes submitted to the post will be charged. All upvotes/downvotes that have not been sent will be automatically refunded by the system.</li>
                        <li>Posts or comments deleted after the order is completed will not be refunded. Please ensure your contents meet the subreddit rules.</li>
                        <li>All of the missing upvotes will be refunded in credits If the targeted post/comment gets removed or the order gets cancelled.</li>
                        <li>The balance after depositing will not be converted to money. We only refund if the service is not as advertised or we stop providing the service.</li>
                    </ul>
                }
            />
            <CustomAccordion id='policy' q="Before You Submit Links:"
                Ans={() =>
                    <ul className='list-disc pl-4'>
                        <li>Make sure that your links are live.</li>
                        <li>Make sure that your contents meet the rules of the targeted <span className='text-[#E18A07] underline'>subreddit</span>.</li>
                        <li>If your links don’t have enough votes after the TAT, you might get some downvoted, or maybe it was hidden by the moderators of the sub. Double-check before opening a ticket on our site</li>
                    </ul>
                }
            />


        </div>
    )
}

export default Faq