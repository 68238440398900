import React from 'react'
import check from '../assets/images/icons/check.svg'
import banner from '../assets/images/banner-1.png'
import { PlanCard } from '../components/deposit'
export function Deposit() {
    return (
        <div className=''>
            <div className='w-[950px] xs:w-full'>
                <h1 className='text-[32px] xs:text-xl font-bold text-center uppercase'>Here’s The Secret to Automatically</h1>
                <h1 className='text-[32px] xs:text-xl text-[#E18A07] font-bold text-center uppercase'>Succes On Reddit Marketing Without Effort</h1>

                <div className='mt-[48px] xs:mt-8 ml-16 xs:ml-0 flex xs:flex-col xs:gap-y-3 justify-center gap-x-9 font-orelega text-[20px] xs:text-base text-[#00000099] font-normal'>
                    <ul className=' flex flex-col gap-y-5 xs:gap-y-3'>
                        <li className='flex items-center gap-x-2'>
                            <img src={check} alt="" className='' />
                            100% Money Back Guarantee
                        </li>
                        <li className='flex items-center gap-x-2'>
                            <img src={check} alt="" className='' />
                            The most absurd prices on <br />the market
                        </li>

                    </ul>
                    <ul className=' flex flex-col gap-y-5 xs:gap-y-3'>
                        <li className='flex items-center gap-x-2'>
                            <img src={check} alt="" className='' />
                            Make customizable orders without limits
                        </li>
                        <li className='flex items-center gap-x-2'>
                            <img src={check} alt="" className='' />
                            High profitability if you know how to<br /> use our services
                        </li>

                    </ul>

                </div>
                <div className='mt-10 w-full flex justify-center'>
                    <div className='flex justify-center items-center w-[764px] xs:w-full h-[75px] rounded-md bg-white'>
                        <h1 className='text-[30px] xs:text-lg font-bold'>You have 0 credits</h1>
                    </div>
                </div>
                <div className='mt-10 w-full flex justify-center'>
                    <div className='flex justify-center items-center w-[764px] xs:w-full  rounded-md relative'>
                        <img src={banner} alt="" />
                        <div className='absolute top-0 text-white w-full h-full flex flex-col justify-center items-center'>
                            <h1 className='text-[28px] xs:text-lg font-black xs:text-center uppercase'>redeem your prize: 30% OFF - One time offer</h1>
                            <h3 className='text-[20px] xs:text-base font-bold uppercase'>(ONLY ON YOUR FIRST PURCHASE)</h3>
                        </div>
                    </div>
                </div>
                <div className='mt-16 flex xs:grid xs:grid-cols-2 xs:gap-y-7 xs:items-center gap-x-7 xs:gap-x-0 justify-between'>
                    <PlanCard header={false} data={{ price: "$10", rate: "0.02$ / Upvote", credits: [{ text: "500 Credits", decorated: false, color: false }], votes: [{ text: "500 Upvotes", decorated: false }] }} />
                    <PlanCard header={{ text: "Popular Plan", color: "#0094E8" }} data={{ price: "$30", rate: "0.02$ / Upvote", credits: [{ text: "1500 Credits", decorated: false, color: false }, { text: "+ 150 Bonus", decorated: false, color: "#FF4607" }], votes: [{ text: "1600 Upvotes", decorated: false }] }} />
                    <PlanCard header={{ text: "Limited Offer", color: "#FF9900" }} data={{ price: "$50", rate: "0.02$ / Upvote", credits: [{ text: "2500 Credits", decorated: false, color: false }, { text: "+ 250 Bonus", decorated: true, color: false }, { text: "+ 500 Bonus", decorated: false, color: "#FF4607" }], votes: [{ text: "2500 Upvotes", decorated: true }, { text: "3000 Upvotes", decorated: false }] }} />
                    <PlanCard header={false} data={{ price: "$200", rate: "0.02$ / Upvote", credits: [{ text: "10000 Credits", decorated: false, color: false }, { text: "+ 1000 Bonus", decorated: false, color: "#FF4607" }], votes: [{ text: "11.1K Upvotes", decorated: false }] }} />
                    <PlanCard header={false} data={{ price: "$1000", rate: "0.018$ / Upvote", credits: [{ text: "55500 Credits", decorated: false, color: false }], votes: [{ text: "55500 Upvotes", decorated: false }] }} />
                    <PlanCard header={false} data={{ price: "$2000", rate: "0.018$ / Upvote", credits: [{ text: "11.1K Credits", decorated: false, color: false }], votes: [{ text: "11.1K Upvotes", decorated: false }] }} />
                </div>
                <div className="divider divider-start after:bg-black mt-20"><h1 className='text-2xl xs:text-xl font-bold'>Customer Data</h1></div>
                <div className='mx-5 mt-7 flex xs:flex-col xs:gap-y-4 gap-x-4'>
                    <div className=''>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">First Name*</span>
                        </label>
                        <input type="text" placeholder="Enter first name" className="input input-bordered w-[415px] xs:w-full h-[52px] bg-transparent" />
                    </div>
                    <div className=''>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">Last Name*</span>
                        </label>
                        <input type="text" placeholder="Enter last name" className="input input-bordered w-[415px] xs:w-full h-[52px] bg-transparent" />
                    </div>
                </div>
                <div className='mx-5 mt-7 xs:mt-4 flex xs:flex-col xs:gap-y-4 gap-x-4'>
                    <div className=''>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">Pais</span>
                        </label>
                        <input type="text" placeholder="Loreum ipsum" className="input input-bordered w-[415px] xs:w-full h-[52px] bg-transparent" />
                    </div>
                    <div className=''>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">Postal Code</span>
                        </label>
                        <input type="text" placeholder="Enter your code" className="input input-bordered w-[415px] xs:w-full h-[52px] bg-transparent" />
                    </div>
                </div>
                <div className="divider divider-start after:bg-black mt-20"><h1 className='text-2xl xs:text-xl font-bold'>Payment Details</h1></div>
                <div className=' mt-8 flex xs:grid xs:grid-cols-2 xs:gap-y-2 gap-x-4'>
                    <div className='flex xs:flex-col gap-x-2 xs:col-span-2'>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">Card number</span>
                        </label>
                        <input type="text" placeholder="xxxx xxxx xxxx xxxx" className="input input-bordered w-[226px] xs:w-full h-[52px] bg-transparent" />
                    </div>
                    <div className='flex xs:flex-col gap-x-2'>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">Exp Date</span>
                        </label>
                        <input type="text" placeholder="mm/yy" className="input input-bordered w-[148px] h-[52px] xs:w-full bg-transparent" />
                    </div>
                    <div className='flex xs:flex-col gap-x-2'>
                        <label className="label">
                            <span className="text-xl xs:text-lg font-medium text-[#00000099]">CVV</span>
                        </label>
                        <input type="text" placeholder="CVV code" className="input input-bordered w-[156px] xs:w-full h-[52px] bg-transparent" />
                    </div>
                </div>
                <div className='mt-9 xs:mt-4 flex xs:flex-col justify-between'>
                    <div className='flex flex-col'>
                        <div className='flex'>
                            <div className="form-control">
                                <label className="cursor-pointer label">
                                    <input type="checkbox" className="checkbox" />
                                    <span className="text-base font-normal text-[#6A6C71] ml-2">Save for future use</span>
                                </label>
                            </div>
                        </div>
                        <p className='mt-6 xs:mt-2 text-[#00000099] text-xs font-medium'>The charges will appear on your statements under <a href="jwsbill.com">jwsbill.com</a> <br />All exchange rates are updated periodically according to the latest market values.</p>
                    </div>
                    <div className='flex flex-col gap-y-3 mr-20 xs:mr-0 xs:mt-4 xs:justify-center xs:items-center xs:w-full '>
                        <div className='w-[261px] h-10 flex justify-center items-center rounded-[20px] text-[#242424] hover:text-white  hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'>
                            <p className=' text-sm font-bold '>
                                Buy and Enjoy the Power
                            </p>
                        </div>
                        <div className='w-[261px] h-10 flex justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424]  cursor-pointer'>
                            <p className=' text-sm font-bold '>
                                Choose other option
                            </p>
                        </div>

                    </div>
                </div>


            </div>

        </div>
    )
}

export default Deposit