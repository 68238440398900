import React from 'react'
import logo from '../assets/images/icons/logo.svg'
import whatsapp from '../assets/images/icons/whatsapp-color.svg'
import telegram from '../assets/images/icons/telegram-color.svg'
export function Contact() {
    return (
        <div className='flex xs:flex-col w-full'>
            <div className='w-5/12 xs:w-full h-full border-r-2'>
                <div className='w-full border-b-2 p-5'>
                    <h1 className='text-2xl xs:text-lg font-bold'>Contact</h1>
                    <h2 className='text-[22px] xs:text-base text-[#00000099] font-medium mt-3'>For anything you need - <span className='text-[#E18A07] font-extrabold'>CONTACT US</span></h2>
                    <p className='mt-9 pr-20 text-lg xs:text-base text-[#00000099] font-medium'>Our purpose is to make you have such great power and ease in Reddit marketing, that you never have to worry again about how to achieve your goal with Reddit after you start using our service.</p>
                </div>
                <div className='mt-3 p-5 '>
                    <img src={logo} alt="" className='w-9 h-9' />
                    <h2 className='mt-8 text-2xl xs:text-lg font-bold'>Upvote Store</h2>
                    <p className='mt-3 text-lg xs:text-base text-[#00000099] font-medium'>World Best reddit Services</p>
                    <h2 className='mt-11 text-2xl xs:text-lg font-bold'>Payments</h2>
                    <p className='mt-3 text-lg xs:text-base text-[#00000099] font-medium'>We accept all major payment <br />methods: Credit Cards, <br />PayPal, CoinBase and Skrill</p>
                    <div className='xs:flex xs:flex-col xs:items-center xs:justify-center'>

                        <ul className='list-disc mt-8 text-xs text-[#00000099] font-medium flex gap-x-6'>
                            <li>Terms and Conditions</li>
                            <li>Privacy Policy</li>

                        </ul>
                        <ul className='list-disc mt-3 text-xs text-[#00000099] font-medium flex gap-x-6'>

                            <li>Blog</li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div className='w-5/12 xs:w-full  pt-40 xs:pt-16  text-center'>
                <h1 className='text-[35px] xs:text-2xl text-[#00000099] font-medium'>Ready to achieve all your <br />goals with Reddit? Contact <br />us now and let's get started!</h1>
                <div className=' mt-6 justify-center flex gap-x-[10px]'>
                    <div className='w-[180px] xs:w-1/2 xs:max-w-[180px] h-10 flex gap-x-[10px] justify-center items-center px-10 py-2.5 rounded-[20px] bg-[#60D669] cursor-pointer'>
                        <img src={whatsapp} alt="" />
                        <p className='text-white text-sm font-bold '>
                            Whastapp
                        </p>
                    </div>
                    <div className='w-[180px] xs:w-1/2 xs:max-w-[180px] h-10 flex gap-x-[10px] justify-center items-center px-10 py-2.5 rounded-[20px] bg-[#2AABEE]  cursor-pointer'>
                        <img src={telegram} alt="" />
                        <p className='text-white text-sm font-bold '>
                            Telegram
                        </p>
                    </div>
                </div>
                <p className='mt-3 text-[#00000099] text-lg xs:text-base font-medium '>contact@upvotestore.com</p>
            </div>


        </div>
    )
}

export default Contact