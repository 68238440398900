import React, { useState } from 'react'

function WithdrawalMethod() {
    const [method, setMethod] = useState("paypal")
    return (
        <div>
            <div className='mt-11'>
                <label className="label">
                    <span className="text-xl font-medium text-[#00000099]">Withdrawal method*</span>
                </label>
                <div className='flex gap-x-4'>
                    {/* paypal */}
                    <div className={`h-[53px] w-[112px] border ${method === "paypal" ? "border-[#E18A07]" : ""} flex justify-center items-center gap-x-2 rounded-md cursor-pointer`}
                        onClick={() => {
                            setMethod("paypal")
                        }}
                    >
                        <input type="radio" name="radio-1" className={`radio ${method === "paypal" ? "radio-warning" : ""}`} checked={method === "paypal" ? true : false} onChange={() => { }} />
                        <p className={`${method === "paypal" ? "text-[#E18A07]" : ""} text-lg font-normal`}>Paypal</p>
                    </div>
                    {/* usdt */}
                    <div className={`h-[53px] w-[155px] border ${method === "usdt" ? "border-[#E18A07]" : ""} flex justify-center items-center gap-x-2 rounded-md cursor-pointer`}
                        onClick={() => {
                            setMethod("usdt")
                        }}
                    >
                        <input type="radio" name="radio-1" className={`radio ${method === "usdt" ? "radio-warning" : ""}`} checked={method === "usdt" ? true : false} onChange={() => { }} />
                        <p className={`${method === "usdt" ? "text-[#E18A07]" : ""} text-lg font-normal`}>USDT TRC20</p>
                    </div>
                    {/* Convert to credits */}
                    <div className={`h-[53px] w-[193px] border ${method === "credits" ? "border-[#E18A07]" : ""} flex justify-center items-center gap-x-2 rounded-md cursor-pointer`}
                        onClick={() => {
                            setMethod("credits")
                        }}
                    >
                        <input type="radio" name="radio-1" className={`radio ${method === "credits" ? "radio-warning" : ""}`} checked={method === "credits" ? true : false} onChange={() => { }} />
                        <p className={`${method === "credits" ? "text-[#E18A07]" : ""} text-lg font-normal`}>Convert to credits</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WithdrawalMethod