import React from 'react'
import PayModal from '../home/PayModal'
import freeCredits from '../../assets/images/icons/FreeCredits.svg'
import arrowUp from '../../assets/images/icons/UpvoteIcon_1.svg'
export function PlanCard({ header, data }) {
    return (
        <div className='text-center relative xs:flex xs:justify-center'>
            <div className='h-20 absolute'>
                {
                    header &&
                    <h1 className="text-2xl xs:text-lg font-bold"
                        style={{ color: header.color }}
                    >{header.text}</h1>
                }
            </div>

            <div className='mt-20 xs:mt-10 w-[154px] h-[620px]  flex flex-col justify-between xs:borderr xs:rounded-md xs:p-5 bg-white rounded-[14px] px-[15px] py-7'>
                <div>
                    <h1 className='text-4xl xs:text-2xl font-black'>{data.price}</h1>
                    <h3 className='mt-[53px] xs:mt-8 text-base xs:text-base font-black'>{data.rate}</h3>

                </div>
                <div className='text-base font-black'>
                    <div className=' flex flex-col items-center'>
                        <img src={freeCredits} alt="" className='mb-[14px]' />

                    </div>
                    <div>
                        {
                            data.credits.map((item, index) =>

                                <p key={index} className={`${item.decorated ? "line-through" : ""}`}
                                    style={{ color: item.color ? item.color : "inherit" }}
                                >{item.text}</p>
                            )
                        }

                    </div>
                </div>

                <div className='xs:mt-9 w-full flex flex-col'>
                    <div className='flex flex-col items-center text-base font-black'>
                        <img src={arrowUp} alt="" className='mb-[14px] w-[63px] h-[63px]' />
                        {
                            data.votes.map((item, index) =>

                                <p key={index} className={`${item.decorated ? "line-through" : ""}`}>{item.text}</p>
                            )
                        }
                    </div>
                    <div className='mt-[54px] w-full flex justify-center'>

                        <PayModal payType="Pay with card" plan={{ price: data.price }} />
                    </div>


                </div>

            </div>
        </div>
    )
}

export default PlanCard