import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { authStore } from '../../contect/auth';
export function Item({ collapsed, setCollapsed, name, icon, activeIcon, path }) {
    const { pathname } = useLocation()
    const { logout } = authStore()
    const isActive = (path !== "logout" && "/" + path === pathname) ? true : false
    return (
        <Link to={"/" + path}
            className='flex justify-center'
            onClick={() => {
                if (path === "logout") {
                    logout()
                }
            }}
            onDoubleClick={() => {
                if (window.innerWidth > 650) {
                    setCollapsed(!collapsed)
                } else {
                    setCollapsed(true)

                }
            }}

        >
            {
                collapsed ?
                    <>
                        {
                            isActive ?
                                <div className='mt-[25px]'>
                                    <div className='flex justify-center items-center text-sm font-bold w-9 h-9 bg-[#FF4607] rounded-[5px]'>
                                        <img className="" src={activeIcon} alt='logo'
                                            width={15}
                                            height={15}
                                        />
                                    </div>
                                </div>

                                :
                                <div className='mt-[25px]'>
                                    <div className='flex justify-center items-center text-sm font-bold '>
                                        <img className="" src={icon} alt='logo'
                                            width={15}
                                            height={15}
                                        />
                                    </div>
                                </div>

                        }
                    </>

                    :
                    <>
                        {
                            isActive ?
                                <div className='mt-[17px]'>
                                    <div className='flex items-center text-sm font-bold w-44 h-9 bg-[#F90] rounded-[5px]'>
                                        <img className="ml-3 mr-2" src={activeIcon} alt='logo'
                                            width={15}
                                            height={15}
                                        />
                                        <div className='font-bold text-sm text-white'>{name}</div>
                                    </div>
                                </div>
                                :
                                <div className='mt-[17px]'>
                                    <div className='flex items-center text-sm font-bold w-44 h-9'>
                                        <img className="ml-3 mr-2" src={icon} alt='logo'
                                            width={15}
                                            height={15}
                                        />
                                        <div className='font-bold text-sm text-[#424242]'>{name}</div>
                                    </div>
                                </div>
                        }
                    </>
            }
        </Link>
    )
}

export default Item