import React from 'react'

// const data = [
//     {
//         order: "",
//         subreddit: "",
//         author: "",
//         award: "",
//         credits: "",
//     }
// ];

export function LatestOrders() {
    return (
        <div className='xs:overflow-scroll xs:pr-2'
            style={{ width: window.innerWidth > 650 ? "100%" : window.innerWidth - 85 }}
        >
            <div className="overflow-x-auto xs:overflow-scroll xs:w-[100%] bg-white mt-4 px-5 pb-5 pt-2 rounded-[5px] min-h-[220px]">
                <table className="table xs:table-xs">
                    {/* head */}
                    <thead className='text-base font-normal text-black'>
                        <tr>
                            <th>Order Info</th>
                            <th>Subreddit</th>
                            <th>Author</th>
                            <th>Award</th>
                            <th>Credits</th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {/* data */}
                        {/* {data.map((item, index) => (
                            <tr key={index}>
                                <th><p>{item.order}</p></th>
                                <th>
                                    <p>{item.subreddit}</p>
                                </th>
                                <th>
                                    <p>{item.author}</p>
                                </th>
                                <th>
                                    <p>{item.award}</p>
                                </th>
                                <th>
                                    <p>{item.credits}</p>
                                </th>
                            </tr>
                        ))} */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default LatestOrders