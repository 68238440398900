import React from 'react'
import reddit from '../../assets/images/reddit-2.svg'
export function BalanceCard() {
    return (
        <div className='relative w-[340px] xs:w-[255px]  h-[170px] xs:h-[130px] px-[18px] py-[15px] rounded-[5px] bg-white'>
            <h4 className='text-xl xs:text-lg font-medium text-[#00000099]'>Balance:</h4>
            <h1 className='text-3xl xs:text-xl font-extrabold mt-5'>0.00 <span className='font-bold'>Credits</span></h1>
            <div className='absolute bottom-[-30px] right-[-25px]'>
                <img src={reddit} alt='reddit' className='w-[120px] xs:w-[90px]' />
            </div>
        </div>
    )
}

export default BalanceCard