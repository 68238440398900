import React from 'react'

export function MainItem({ name, collapsed }) {
    return (
        <div className='mt-6'>
            {
                collapsed ? <div className='text-xs font-extrabold text-center'>{name}</div>
                    :
                    <div className='text-xs font-extrabold bg-[#424242] text-white rounded-ee-[10px] w-[55%] h-10 flex justify-center items-center'>{name}</div>
            }
        </div>

    )
}

export default MainItem