import React from 'react'
import icon from '../assets/images/icons/sitIcon.svg'
export function Loading() {
    return (
        // <div className='w-full h-screen'>
        //     <div className='flex flex-col justify-center items-center'>
        //         <img src={icon} alt="" width={210} height={375} />
        //         <h1 className='text-[35px] font-bold'>Page Loading</h1>
        //         <p className='text-2xl font-normal text-[#00000099]'>Please wait few minutes for page to load</p>
        //     </div>

        // </div>
        <div className='w-full'>
            <div className='flex flex-col justify-center items-center text-center xs:mt-40'>
                <img src={icon} alt="" className='w-52 xs:w-28 h-[375px] xs:h-44' />
                <h1 className='text-[35px] xs:text-xl font-bold'>Page Loading</h1>
                <p className='text-2xl xs:text-lg font-normal text-[#00000099]'>Please wait few minutes for page to load</p>
            </div>

        </div>
    )
}

export default Loading