import React from 'react'
import avatar from '../../assets/images/avatar.png'
import { authStore } from '../../contect/auth';
import LoginModal from '../auth/LoginModal';
import RegisterModal from '../auth/RegisterModal';
export function Header() {
    const { isLogin } = authStore()
    // const toggleTheme = () => {
    //     const currentTheme = document.querySelector("html").getAttribute('data-theme');
    //     const nextTheme = currentTheme === 'dark' ? 'light' : 'dark';
    //     document.querySelector("html").setAttribute('data-theme', nextTheme);
    // };
    return (
        <div className='w-full'>
            {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
            {
                isLogin ?
                    <div className='flex justify-end xs:justify-start gap-x-3 mr-6'>
                        <div className='flex'>
                            <img src={avatar} className='mr-3' alt='avatar' />
                            <div className='flex flex-col justify-between py-[5px]'>
                                <h5 className='text-base font-bold'>Johndoe@gmail.com</h5>
                                <p className='text-sm font-normal text-[#00000099]'>Balance: <span className='text-[#30DC63]'>0.00</span></p>

                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex justify-end gap-x-3 mr-6 xs:mr-0 xs:justify-center'>
                        <LoginModal />
                        <RegisterModal />
                    </div>

            }
        </div>
    )
}

export default Header