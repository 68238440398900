import React from 'react'
import { Link } from 'react-router-dom';
export function Indices() {
    return (
        <div className='text-orange-600'>
            {/* pages */}
            <div className='flex flex-col gap-y-3'>
                <h1 className='text-2xl font-bold text-black'>Pages</h1>
                <Link to="/" >1. Home</Link>
                <Link to="/dashboard" >2. Dashboard</Link>
                <Link to="/new-order" >3. New Order</Link>
                <Link to="/deposit" >4. Deposit</Link>
                <Link to="/deposit-2" >5. Deposit-2</Link>
                <Link to="/transactions" >6. Transactions</Link>
                <Link to="/settings" >7. Settings</Link>
                <Link to="/contact" >8. Contact</Link>
                <Link to="/usage-guide" >9. Usage Guide</Link>
                <Link to="/customer-range" >10. Customer Range</Link>
                <Link to="/faq" >11. Faq</Link>
                <Link to="/loading" >13. Loading</Link>
                <Link to="/404" >12. 404</Link>

            </div>
            {/* Modal */}
            <div className='flex flex-col gap-y-3 mt-5'>
                <h1 className='text-2xl font-bold text-black'>Modals</h1>
                <Link to="/withdrawal-modal" >1. Withdrawal modal</Link>
                <Link to="/payment-modal" >2. Payment modal</Link>
                <Link to="/card-modal" >3. Card modal</Link>
                <Link to="/verify-modal" >4. Verify modal</Link>
                <Link to="/declined-modal" >5. Declined modal</Link>
                <Link to="/pay-modal" >6. Pay modal</Link>
                <Link to="/login" >7. Login</Link>
                <Link to="/register" >8. Register</Link>
            </div>
        </div>
    )
}

export default Indices