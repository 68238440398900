// import App from './App';
import LoginModal from './components/auth/LoginModal';
import RegisterModal from './components/auth/RegisterModal';
import WithdrawalModal from './components/affiliate/WithdrawalModal';
import { PaymentModal } from './components/home';
import CardMethodModal from './components/home/CardMethodModal';
import PayModal from './components/home/PayModal';
import TransactionDeclinedModal from './components/home/TransactionDeclinedModal';
import VerifyCardModal from './components/home/VerifyCardModal';
import Layout from './components/layout/Layout';
import { Contact, CustomerRange, Dashboard, Deposit, Faq, Home, NewOrder, Settings, Transactions, UsageGuide, Error404, Indices, Affiliate, Loading } from './pages';
import {
    createBrowserRouter,
} from "react-router-dom";
// Modals

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />, // 
        errorElement: <Error404 />,
        children: [
            { index: true, element: <Home /> },
            { path: "dashboard", element: <Dashboard /> },
            { path: "new-order", element: <NewOrder /> },
            { path: "deposit", element: <Deposit /> },
            { path: "transactions", element: <Transactions /> },
            { path: "usage-guide", element: <UsageGuide /> },
            { path: "affiliate", element: <Affiliate /> },
            { path: "customer-range", element: <CustomerRange /> },
            { path: "settings", element: <Settings /> },
            { path: "faq", element: <Faq /> },
            { path: "contact", element: <Contact /> },
            { path: "contact-telegram", element: <Contact /> },
            { path: "contact-whatsapp", element: <Contact /> },
            { path: "logout", element: <Home /> },
            { path: "404", element: <Error404 /> },
            { path: "loading", element: <Loading /> },
            { path: "indices", element: <Indices /> },
            // Private
            // Indices
            { path: "indices", element: <Indices /> },
            // Modals
            { path: "withdrawal-modal", element: <WithdrawalModal /> },
            { path: "payment-modal", element: <PaymentModal /> },
            { path: "card-modal", element: <CardMethodModal /> },
            { path: "verify-modal", element: <VerifyCardModal /> },
            { path: "declined-modal", element: <TransactionDeclinedModal /> },
            { path: "pay-modal", element: <PayModal /> },
            { path: "login", element: <LoginModal /> },
            { path: "register", element: <RegisterModal /> },

        ],
    },

    // ... other routes not using the sidebar

]);

