import React from 'react'
import downDouble from '../../assets/images/icons/Capa44.svg'
import star from '../../assets/images/icons/star44.svg'
export function DiscountRow({ award, content }) {
    return (
        <div className='flex xs:grid xs:grid-cols-2 xs:gap-y-8 w-full xs:gap-x-4 ml-6 xs:ml-0'>
            <div className='mr-[55px] xs:col-span-2 xs:w-full xs:flex xs:justify-center xs:items-center'>

                <div className='w-[132px] xs:w-[100px] h-[132px] xs:h-[100px] mt-6 flex justify-center items-center rounded-[9px] bg-[#FF4607]'>
                    <img src={award} alt="" className='xs:w-3/6' />
                </div>
            </div>
            <div className='w-20 xs:text-center xs:w-full mr-16 flex justify-center items-center xs:col-span-2 text-xl xs:text-xl text-[#000000CC] font-bold'>
                {content.price}
            </div>
            <div className=' flex items-center gap-x-5'>
                <img src={downDouble} alt="" className='h-10 w-10' />
                <p className='w-[278px] xs:w-full mr-5 xs:text-center flex items-center text-lg xs:text-base text-[#00000099] font-normal'>

                    {content.desc}
                </p>

            </div>
            <div className=' flex items-center gap-x-5'>
                <img src={star} alt="" className='h-10 w-10' />

                <p className='flex justify-center items-center text-lg xs:text-lg text-[#00000099] font-normal'>
                    {content.value}
                </p>
            </div>

        </div>
    )
}

export default DiscountRow