import React from 'react'
import close from '../../assets/images/icons/close.svg'
import santander from '../../assets/images/icons/santander.svg'
import mastercard from '../../assets/images/icons/mastercard.svg'
import phone from '../../assets/images/icons/phone.svg'
import TransactionDeclinedModal from './TransactionDeclinedModal'
import { closeModal, openModal } from '../modals/control'

export function VerifyCardModal() {
    const verifyModalKey = "verify_card_modal"
    return (
        <div>

            <div className=' w-[261px] h-12 flex justify-center items-center rounded-[20px] text-[#242424] hover:text-white hover:bg-[#242424] border border-solid border-[#242424] cursor-pointer'
                onClick={() => openModal(verifyModalKey)}
            >
                <p className='text-sm font-bold '>
                    Buy and Enjoy the Power
                </p>
            </div>

            <dialog id={verifyModalKey} className="modal glass1">
                <div className="modal-box w-[803px] max-w-[803px] py-[60px] px-[30px] rounded-[5px] relative"
                    style={{ maxWidth: window.innerWidth > 650 ? "803px" : window.innerWidth - 50 }}
                >
                    <div className=' flex flex-row-reverse absolute top-5 right-5'>
                        <button className='focus-visible:outline-none'
                            onClick={() => closeModal(verifyModalKey)}
                        ><img src={close} alt="" /></button>
                    </div>
                    <div className='flex absolute top-5 left-5'>
                        <h1 className='text-[22px] xs:text-xl font-bold'>Verify Payment</h1>
                    </div>

                    <div className='divider mx-5 xs:mx-0'></div>
                    <div className='flex justify-between xs:gap-x-4 mx-20 xs:mx-0'>
                        <img src={santander} alt="" className='xs:w-1/2' />
                        <img src={mastercard} alt="" className='xs:w-1/2' />
                    </div>
                    <div className='divider mx-5 xs:mx-0'></div>
                    <h1 className='text-[35px] xs:text-xl font-bold text-center'>Validate this purchase to continue</h1>
                    <div className='flex justify-center mt-10'>
                        <div className="radial-progress text-[#E18A07]" style={{ "--value": "70", "--size": "8rem", "--thickness": "2px" }} role="progressbar"><p className='text-[30px] xs:text-2xl text-black font-normal'>06:40</p></div>
                    </div>
                    <div className='mt-[50px] xs:mt-10 mx-20 xs:mx-0 flex justify-evenly xs:justify-around'>

                        <div className='flex justify-center items-center w-[90px] h-[90px] rounded-full bg-[#F2F2F2]'>

                            <img src={phone} alt="" />
                        </div>
                        <p className='w-4/6 xs:text-center text-lg xs:text-base font-normal'>Confirm the operation in the Santader app or in the message we have sent to your mobile phone and continue on the merchant's website</p>
                    </div>
                    <div className='divider mx-5 xs:mx-0 mt-[60px] xs:mt-12'></div>
                    <div className="flex justify-center items-center w-full">
                        <TransactionDeclinedModal />
                    </div>


                </div>
            </dialog>
        </div>
    )
}

export default VerifyCardModal