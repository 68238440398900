import React from 'react'
export function FilterBtn() {
    return (
        <select defaultValue="yearly" className="select text-center text-sm text-[#242424] font-bold bg-transparent border rounded-[20px] !border-[#242424] w-[150px] h-10">
            <option value="yearly">Yearly</option>
            <option>Monthly</option>
            <option>Daily</option>
        </select>
    )
}

export default FilterBtn