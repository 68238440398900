const closeAll = () => {
    try {
        const modals = document.querySelectorAll('dialog');
        modals.forEach(modal => {
            modal.close();
        });
    } catch (error) {

    }

}
export const openModal = (modalId) => {
    try {
        closeAll() //  close all open modals
        document.getElementById(modalId).showModal()
    } catch (error) {

    }

}
export const closeModal = (modalId) => {
    try {
        document.getElementById(modalId).close()
    } catch (error) {

    }
}