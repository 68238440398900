import React, { useState } from 'react'
import check from '../assets/images/icons/check.svg'
import Table from '../components/affiliate/Table'
import WithdrawalModal from '../components/affiliate/WithdrawalModal'
export function Affiliate() {
    const [activeTab, setActiveTab] = useState(1)
    return (
        <div className='mr-60 xs:mr-0'>
            <h1 className='text-[43px] xs:text-xl text-[#E18A07] font-extrabold xs:text-center'>HOW TO GET A LOT OF MONEY <br />EASY WITH VERY LITTLE EFFORT</h1>
            <h3 className='mt-6 text-2xl xs:text-lg font-bold'>Using our simple and easy affiliate program</h3>
            <h4 className='mt-6 xs:mt-8 text-xl xs:text-base text-[#000000CC] font-bold'>How to use program? <span className='text-[#E18A07]'>SIMPLE 3 STEP GUIDE</span></h4>
            <ul className='list-disc mt-[14px] xs:mt-2 xs:ml-4 flex flex-col gap-y-[14px] text-lg xs:text-base font-semibold'>
                <li>STEP 1: <span className='text-[#00000099] font-normal'>Copy and paste the reference link that we provide you with <br />your unique code</span></li>
                <li>STEP 2: <span className='text-[#00000099] font-normal'>Promote the link and for every friend who registers and <br />spends money on our panel you receive 15% commission</span></li>
                <li>STEP 3: <span className='text-[#00000099] font-normal'>When you have more than $50 in your balance, press the <br />"APPLY OFR WITHDRAW" button and receive your money in a few days</span></li>
            </ul>
            <div className="divider divider-start after:bg-black mt-20 xs:mt-12"><h1 className='text-2xl xs:text-xl font-bold'>Affiliate Program Details</h1></div>
            <div className='mt-[48px] xs:mt-12 flex xs:flex-col gap-x-9 font-orelega text-lg xs:text-base text-[#00000099] font-normal'>
                <ul className='mr-10 xs:w-full flex flex-col gap-y-5'>
                    <li className='flex items-start gap-x-2'>
                        <img src={check} alt="" className='pt-1' />
                        Get a 15% commission on the deposit payments of <br />all the friends you invite to use our panel
                    </li>
                    <li className='flex items-start gap-x-2'>
                        <img src={check} alt="" className='pt-1' />
                        Minimum payment: $50
                    </li>
                    <li className='flex items-start gap-x-2'>
                        <img src={check} alt="" className='pt-1' />
                        Affiliate Program is available to all users.
                    </li>
                </ul>
                <ul className='w-1/2 xs:w-full flex flex-col gap-y-5'>
                    <li className='flex items-start gap-x-2'>
                        <img src={check} alt="" className='pt-1' />
                        There is not limit.
                    </li>
                    <li className='flex items-start gap-x-2'>
                        <img src={check} alt="" className='pt-1' />
                        You will receive your payment in a <br />maximum of 7 days after requesting it.
                    </li>

                </ul>

            </div>
            <p className='mt-9 text-lg xs:text-base font-normal text-center'>NOTICE: Any cheating activity is prohibited. We have all rights to ban or remove cheat accounts.</p>
            <h1 className='mt-16 text-[35px] xs:text-xl text-[#E18A07] font-bold text-center italic'>Get a unique and limited lifetime discount of<br /> 20% on all our products</h1>
            <p className='mt-5 text-xl xs:text-lg font-bold text-center'>Make a special promotion of our website, for this contact us by email to inform us</p>
            <div className="mt-24 xs:mt-12 divider divider-start after:bg-black"><h1 className='text-2xl font-bold'>Referral</h1></div>
            <div className='flex xs:flex-col xs:gap-y-4 gap-x-7 mt-8'>
                <div className=''>
                    <label className="label">
                        <span className="text-xl xs:text-lg font-medium text-[#00000099]">Your referral link:</span>
                    </label>
                    <input type="text" placeholder="Referral link" className="input input-bordered w-[240px] xs:w-full h-[52px] bg-transparent" />
                </div>
                <div className=''>
                    <label className="label">
                        <span className="text-xl xs:text-lg font-medium text-[#00000099]">Your code:</span>
                    </label>
                    <input type="text" placeholder="Code" className="input input-bordered w-[150px] xs:w-full h-[52px] bg-transparent" />
                </div>
            </div>
            <div className='flex xs:justify-center gap-x-28 xs:gap-x-14 mt-11'>
                <div className='flex flex-col gap-y-[10px]'>
                    <h1 className='text-lg xs:text-base text-[#00000099] font-medium'>Reffered</h1>
                    <h1 className='text-[30px] xs:text-xl font-medium'>0.00</h1>
                </div>
                <div className='flex flex-col gap-y-[10px]'>
                    <h1 className='text-lg xs:text-base text-[#00000099] font-medium'>Ref rate:</h1>
                    <h1 className='text-[30px] xs:text-xl font-medium'>10%</h1>
                </div>
                <div className='flex flex-col gap-y-[10px]'>
                    <h1 className='text-lg xs:text-base text-[#00000099] font-medium'>Balance:</h1>
                    <h1 className='text-[30px] xs:text-xl font-medium'>$0</h1>
                </div>
            </div>
            <WithdrawalModal />
            <div className='border-b-[2px]'>
                <div className="mt-24 w-56 tabs tabs-bordered ">
                    <div className={`tab text-lg ${activeTab === 1 ? "tab-active font-bold" : "font-semibold text-[#00000099]"}`}
                        onClick={() => {
                            setActiveTab(1)
                        }}
                    >Rewards</div>
                    <div className={`tab text-lg ${activeTab === 2 ? "tab-active font-bold" : "font-semibold text-[#00000099]"}`}
                        onClick={() => {
                            setActiveTab(2)
                        }}
                    >Withdraw</div>
                </div>
            </div>
            <Table />


        </div>
    )
}

export default Affiliate